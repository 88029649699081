import { Box } from '@mui/material';
import { Align } from 'layout';
import { FC } from 'react';
import ListEmployeeTable from '../../components/User/Employee/ListEmployeeTable';
import { StyledBoxWithPadding, StyledTextH2 } from '../../design/theme/shared-style';

export const EmployeesManagement: FC = () => {
  return (
    <StyledBoxWithPadding>
      <Box mx={0} my={2} p={0}>
        <StyledTextH2>
          Listado De Colaboradores
        </StyledTextH2>
      </Box>

      <Align v-center>
        <ListEmployeeTable />
      </Align>
    </StyledBoxWithPadding>
  );
};
