import { createIntl, createIntlCache, IntlShape, MessageDescriptor, PrimitiveType } from 'react-intl';
import { ReactNode } from 'react';
import { getDefaultLocale, setDefaultLocale } from './helpers';
import { Locale } from './enums/locale.enum';
import en from './en.json';
import fr from './fr.json';

const messages: Record<Locale, Record<string, string>> = {
  en: en as unknown as Record<string, string>,
  fr: fr as unknown as Record<string, string>,
};

const cache = createIntlCache();

interface IntlTranslateParams extends MessageDescriptor {
  values?: Record<string, PrimitiveType | ReactNode>;
}

class Intl {
  private static localeFallback = Locale.en;
  private static supportedLanguages = Object.values(Locale);

  private locale: Locale;
  private intl: IntlShape;

  constructor(locale: Locale) {
    this.locale = this.getSafeLocale(locale);

    this.intl = createIntl(
      {
        locale: this.locale,
        messages: messages[this.locale] ?? messages[Intl.localeFallback],
        defaultLocale: Intl.localeFallback,
      },
      cache,
    );
  }

  setLocale(locale: Locale): void {
    this.locale = this.getSafeLocale(locale);

    this.intl = createIntl(
      {
        locale: this.locale,
        messages: messages[this.locale] ?? messages[Intl.localeFallback],
        defaultLocale: Intl.localeFallback,
      },
      cache,
    );

    setDefaultLocale(locale);
  }

  getLocale(): Locale {
    return this.locale;
  }

  private getSafeLocale(locale: Locale): Locale {
    return Intl.supportedLanguages.includes(locale)
      ? locale
      : Intl.localeFallback;
  }

  translate(descriptor: IntlTranslateParams): string {
    const { values, ...rest } = descriptor;
    const descriptorWithDefaultMessage: MessageDescriptor = { ...rest };

    return this.intl.formatMessage(descriptorWithDefaultMessage, values) as string;
  }
}

const defaultLocale = getDefaultLocale();

const intl = new Intl(defaultLocale);

export { intl };