import ExcelJS from 'exceljs';
import { UserRoleEnum } from '../../../../types/user-role.enum';
import { UserStatusEnum } from '../../../../types/user-status.enum';
import moment from 'moment/moment';
import { logger } from '../../../../utilities/logger/Logger';
import appLayoutMap from '../../../../utilities/appLayoutMap';
// @ts-ignore
import { saveAs } from 'file-saver';
import { User } from '../../../../types/user.dto';
import { ComplaintDTO, ComplaintResponseDTO, ReportedUsersDTO } from '../../../../types/complaint.dto';
import { firstLetterUppercase } from '../../../../utilities/i18n/helpers';
import {
  ComplaintPriorityEnum,
  ComplaintPrioritySpanishEnum,
  ComplaintStatus,
  ComplaintStatusEnum,
} from '../../../../enums/complaint.enum';

const formatStatus = (status: UserStatusEnum) => {
  switch (status) {
    case UserStatusEnum.Active:
      return '✅ Activo';
    case UserStatusEnum.Pending:
      return '🕐 Pendiente App';
    case UserStatusEnum.Deactivated:
      return '❌ Deshabilitado';
    default:
      return '⏳ En revisión';
  }
};

const formatField = (field: any) => {
  return field && field !== 'null' ? field : '--';
};

const formatDate = (date: string | Date | null | undefined) => {
  return date && date !== 'null' ? moment.utc(date).format('DD/MM/YYYY') : '--';
};

const formatTwispiEndDate = (date: string | Date | null | undefined) => {
  if (date && date !== 'null') {
    const fecha = new Date(date);
    const opcionesFormato: { hour: '2-digit', minute: '2-digit' } = { hour: '2-digit', minute: '2-digit' };
    const horaYMinutos = fecha.toLocaleTimeString('es-419', opcionesFormato);
    return `${fecha.toLocaleDateString('es-419')} ${horaYMinutos}`;
  }
  return '--';
};

const formatSalary = (salary: any) => {
  return salary ? `$ ${typeof salary !== 'number' ? salary.salaryAmount : salary}` : '--';
};

const formatClabe = (clabes: any) => {
  try {
    const indexActive = clabes?.findIndex((clabe: any) => clabe.active === true);
    return clabes[indexActive]?.code || '--';
  } catch (e: any) {
    logger.error(e);
    return '--';
  }
};

const formatBank = (clabes: any) => {
  try {
    const indexOfClabe = clabes?.findIndex((clabe: any) => clabe.active === true);
    return (clabes[indexOfClabe]?.active && clabes[indexOfClabe]?.bank?.code) || '--';
  } catch (e: any) {
    logger.error(e);
    return '--';
  }
};

const formatAppLayout = (appLayout: string | null) => {
  return (appLayout && appLayoutMap[appLayout]) || '--';
};

const formatProfiles = (profiles: any) => {
  try {
    let latestActiveProfile;
    if (profiles && profiles.length > 0) {
      latestActiveProfile = profiles.reduce((prev: any, current: any) => (prev.updatedAt > current.updatedAt) ? prev : current);
    }
    return latestActiveProfile?.profile.name || '--';
  } catch (e: any) {
    logger.error(e);
    return '--';
  }
};

export const exportEmployeeData = async (companySelected: any, loggedUser: User, tableData: User[]): Promise<void> => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Usuarios');
  const hasContract = companySelected && companySelected.contractData && companySelected.contractData.activeContract;
  const headers = [
    'ID Twispi', 'Estado', 'Nombre(s)', 'Apellido Paterno', 'Apellido Materno', 'Fecha de Nacimiento', 'RFC',
    'CURP', 'Sexo', 'Email', 'Teléfono', 'ID de Empleado', 'Puesto', 'Área', 'Ubicación',
    'Fecha de Ingreso', 'Email empresarial', 'Sueldo neto por periodo', 'Periodicidad', 'CLABE',
    'Código banco', 'Fecha de Egreso', 'Fecha de Baja Twispi',
  ];

  if(loggedUser?.role === UserRoleEnum.ADMIN) {
    headers.push('Perfil Twispi');
    headers.push('AppLayout');
  } else if (hasContract) {
    headers.push('Perfil Twispi');
  } else {
    headers.push('AppLayout');
  }

  const headerRow = worksheet.addRow(headers);
  loadHeaderStyle(headerRow);
  const tableDataFilteredToExport = tableData.filter((user) => user.status === UserStatusEnum.Active || user.status === UserStatusEnum.Pending);
  tableDataFilteredToExport.forEach((data) => {
    const rowData = [
      data.id,
      formatStatus(data.status),
      formatField(data.name),
      formatField(data.firstLastname),
      formatField(data.secondLastname),
      formatDate(data.birthday),
      formatField(data.rfc),
      formatField(data.curp),
      formatField(data.sex),
      formatField(data.email),
      formatField(data.mobile),
      formatField(data.companyEmployeeNumber),
      formatField(data.position),
      formatField(data.area),
      formatField(data.location),
      formatDate(data.startDate),
      formatField(data.companyEmail),
      formatSalary(data.netSalary),
      formatField(data.paymentFrequency),
      formatClabe(data.clabes),
      formatBank(data.clabes),
      formatDate(data.endDate),
      formatTwispiEndDate(data.twispiEndDate),
    ];
    if(loggedUser?.role === UserRoleEnum.ADMIN) {
      rowData.push(formatProfiles(data.profiles));
      rowData.push(formatAppLayout(data.appLayout));
    } else if (hasContract) {
      rowData.push(formatProfiles(data.profiles));
    } else {
      rowData.push(formatAppLayout(data.appLayout));
    }
    const row = worksheet.addRow(rowData);

    row.eachCell((cell, colNumber) => {
      if (headers[colNumber - 1] === 'ID Twispi') {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D3D3D3' },
        };
      }
    });
    row.height = 25;
  });
  worksheet.getColumn(1).width = 20;
  worksheet.getColumn(2).width = 15;
  worksheet.getColumn(3).width = 25;
  worksheet.getColumn(4).width = 20;
  worksheet.getColumn(5).width = 20;
  worksheet.getColumn(6).width = 15;
  worksheet.getColumn(7).width = 20;
  worksheet.getColumn(8).width = 20;
  worksheet.getColumn(9).width = 10;
  worksheet.getColumn(10).width = 25;
  worksheet.getColumn(11).width = 15;
  worksheet.getColumn(12).width = 15;
  worksheet.getColumn(13).width = 20;
  worksheet.getColumn(14).width = 20;
  worksheet.getColumn(15).width = 20;
  worksheet.getColumn(16).width = 15;
  worksheet.getColumn(17).width = 25;
  worksheet.getColumn(18).width = 20;
  worksheet.getColumn(19).width = 15;
  worksheet.getColumn(20).width = 20;
  worksheet.getColumn(21).width = 15;
  worksheet.getColumn(22).width = 15;
  worksheet.getColumn(23).width = 25;
  worksheet.getColumn(24).width = 20;
  if(loggedUser?.role === UserRoleEnum.ADMIN) {
    worksheet.getColumn(25).width = 24;
  }
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'usuarios.xlsx');
};

const loadHeaderStyle = (headerRow: ExcelJS.Row) => {
  headerRow.eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '87CEFA' },
    };
    cell.font = {
      bold: true,
    };
  });
};

export const exportComplaintData = async (complaintList: ComplaintResponseDTO | null) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Denuncias');
  const headers = [
    'Título', 'Descripción', 'Prioridad', 'Denunciante', 'Fecha de Creación', 'Estado',
    'Nombre Denunciado', 'Categoría', 'Colaboradores Denunciados', 'Resolución'
  ];

  const headerRow = worksheet.addRow(headers);
  loadHeaderStyle(headerRow);
  if(!complaintList) {
    return;
  }
  const tableDataMixedToExport = Object.values(complaintList)
    .filter((array): array is ComplaintDTO[] => Array.isArray(array))
    .flat(1);
  tableDataMixedToExport.sort((a, b) => {
    const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
    const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
    return dateB - dateA;
  });
  tableDataMixedToExport.forEach((complaint) => {
    const rowData = [
      formatField(firstLetterUppercase(complaint.title)),
      formatField(complaint.body),
      formatField(ComplaintPrioritySpanishEnum[complaint.priority] || complaint.priority),
      formatField(complaint.anonymous ? 'Anónimo' : `${complaint.user?.name} ${complaint.user?.firstLastname} - ${complaint.user?.email}`),
      formatDate(complaint.createdAt),
      formatField(ComplaintStatus[complaint.status] || complaint.status),
      formatField(complaint.reportedUsersEntry),
      formatField(complaint.type.name),
      formatField(getNamesAndLastnames(complaint.usersReported)),
      formatField(complaint.bodyResolution),
    ];
    const row = worksheet.addRow(rowData);
    row.height = 25;
    formatPriority(row, complaint);
    formatStatusCell(row, complaint);
  });
  worksheet.getColumn(1).width = 30;
  worksheet.getColumn(2).width = 30;
  worksheet.getColumn(3).width = 20;
  worksheet.getColumn(4).width = 30;
  worksheet.getColumn(5).width = 15;
  worksheet.getColumn(6).width = 17;
  worksheet.getColumn(7).width = 20;
  worksheet.getColumn(8).width = 17;
  worksheet.getColumn(9).width = 30;
  worksheet.getColumn(10).width = 30;
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'denuncias.xlsx');
};

const formatPriority = (row: ExcelJS.Row, complaint: ComplaintDTO) => {
  const priorityCell = row.getCell(3);
  switch (complaint.priority) {
    case ComplaintPriorityEnum.HIGH:
      priorityCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DC143C' },
      };
      priorityCell.font = { color: { argb: 'FFFFFF' } };
      break;
    case ComplaintPriorityEnum.MEDIUM:
      priorityCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF7F50' },
      };
      priorityCell.font = { color: { argb: '000000' } };
      break;
    case ComplaintPriorityEnum.LOW:
      priorityCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '87CEEB' },
      };
      priorityCell.font = { color: { argb: '000000' } };
      break;
  }
  priorityCell.border = {
    top: { style: 'thin', color: { argb: 'aaaaaa' } },
    left: { style: 'thin', color: { argb: 'aaaaaa' } },
    bottom: { style: 'thin', color: { argb: 'aaaaaa' } },
    right: { style: 'thin', color: { argb: 'aaaaaa' } },
  };
};

const formatStatusCell = (row: ExcelJS.Row, complaint: ComplaintDTO) => {
  const statusCell = row.getCell(6);
  switch (complaint.status) {
    case ComplaintStatusEnum.OPEN:
      statusCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '3DD9534F' },
      };
      statusCell.font = { color: { argb: 'FFFFFF' } };
      break;
    case ComplaintStatusEnum.CLOSE:
      statusCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '2610957D' },
      };
      statusCell.font = { color: { argb: 'FFFFFF' } };
      break;
    case ComplaintStatusEnum.INVESTIGATION:
      statusCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '26337AB7' },
      };
      statusCell.font = { color: { argb: 'FFFFFF' } };
      break;
    case ComplaintStatusEnum.PROGRESS:
      statusCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '26F0AD4E' },
      };
      statusCell.font = { color: { argb: '000000' } };
      break;
  }

  statusCell.border = {
    top: { style: 'thin', color: { argb: 'aaaaaa' } },
    left: { style: 'thin', color: { argb: 'aaaaaa' } },
    bottom: { style: 'thin', color: { argb: 'aaaaaa' } },
    right: { style: 'thin', color: { argb: 'aaaaaa' } },
  };
};

export const getNamesAndLastnames = (users: ReportedUsersDTO[]): string => {
  return users.map(user => `${user.name} ${user.firstLastname}`).join(', ');
}