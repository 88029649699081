import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ThemeProvider,
    Typography,
} from '@mui/material';
import { ConfirmButton } from 'components/Shared/Buttons/ConfirmButton';
import { CloseDialogButton } from 'design/Button/CloseDialogButton';
import { FC } from 'react';
import * as XLSX from 'xlsx';
import { firstLetterUppercase } from '../../../utilities/i18n/helpers';
import styled from 'styled-components';
import { CloudDownload } from '@mui/icons-material';


export const ImportUserResultsModal: FC<{
    onClose: () => void;
    open: boolean;
    resultsInfo: any;
}> = ({ open, onClose, resultsInfo }) => {
    function generateAndDownloadXLSX() {
        const wb = XLSX.utils.book_new();
        const ws_data: any[] = [];
        const headers = [
            "Resultado de carga",
            "Detalle del error",
            ...Object.keys(resultsInfo[0].rowinfo).map(header => firstLetterUppercase(header))
        ];
        ws_data.push(headers);
        resultsInfo.forEach((item: any) => {
            const row = [
                item.loadSuccess ? "Correcta" : "Error",
                item.loadSuccess ? "" : item.errorInfo,
                ...Object.values(item.rowinfo)
            ];
            ws_data.push(row);
        });
        const ws = XLSX.utils.aoa_to_sheet(ws_data);
        ws['!cols'] = headers.map(header => {
            const maxWidth = Math.max(...ws_data.map(row => (row[headers.indexOf(header)] || '').toString().length), header.length);
            return { wch: Math.max(maxWidth, 10) };
        });
        XLSX.utils.book_append_sheet(wb, ws, "Resultados de Carga");
        XLSX.writeFile(wb, "Resultados_Carga_Usuarios.xlsx");
    }

    const StyleButtonContainer = styled.div`
        height: 20%;
        width: 60%;
        margin-top: 25px;
    `;

    return (
        <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle textAlign="left">
                Descargar resumen
                <CloseDialogButton onClose={onClose} />
            </DialogTitle>
            <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography>Aqui puedes descargar tu resumen con los resultado de la carga.</Typography>
                <StyleButtonContainer>
                    <Button color="success"
                        component="label"
                        variant="outlined"
                        startIcon={<CloudDownload />} onClick={generateAndDownloadXLSX}>Descargar Resumen</Button>
                </StyleButtonContainer>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <ConfirmButton handleClick={onClose}>
                    <strong>Cerrar</strong>
                </ConfirmButton>
            </DialogActions>
        </Dialog>
    );
};

