import { Dialog, DialogTitle, DialogContent, Skeleton, Button, DialogActions, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { FC, useState, useEffect } from 'react';
import { CloseDialogButton } from "../../design/Button/CloseDialogButton";
import { ComplaintDTO } from "../../types/complaint.dto";
import { CancelButton } from "../../components/Shared/Buttons/CancelButton";
import { ConfirmButton } from "../../components/Shared/Buttons/ConfirmButton";
import { ComplaintPriorityEnum, ComplaintPrioritySpanishEnum } from "../../enums/complaint.enum";
import { updateComplaintPriority } from "../../api/complaint/complaint.api";
import { toast } from "react-toastify";


export const PrioritySelectorModal: FC<{
    complaintToDetail: ComplaintDTO | null;
    reloadComplaints: () => Promise<void>;
    loadSavedComplaint?: (complaint: ComplaintDTO) => void;
    onClose: () => void;
    open: boolean;
    holdingState?: {
        droppableId: string,
        index: number
    } | null;
}> = ({ open, onClose, complaintToDetail, reloadComplaints, holdingState, loadSavedComplaint }) => {
    const [priority, setPriority] = useState(complaintToDetail?.priority || '');
    const selectPriority = async () => {
        if(priority && complaintToDetail) {
            toast.info('Estableciendo prioridad...', {
                position: toast.POSITION.TOP_CENTER,
            });
            const savedComplaint = await updateComplaintPriority({
                complaintId: complaintToDetail?.id,
                newPriority: priority as ComplaintPriorityEnum,
                holdingState
            });
            if(loadSavedComplaint && savedComplaint) {
                loadSavedComplaint(savedComplaint);
                toast.dismiss();
                toast.success('Prioridad establecida correctamente', {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        }
        reloadComplaints();
        handleClose();
    };
    const handleClose = () => {
        onClose();
        setPriority('');
    };
    useEffect(() => {
        if(complaintToDetail?.priority) {
            setPriority(complaintToDetail?.priority);
        }
    }, [open])
    return (
        <Dialog open={open} fullWidth={true} maxWidth={'xs'}>
            <DialogTitle textAlign="left">
                <div>Establecer prioridad</div>
                <CloseDialogButton onClose={handleClose} />
            </DialogTitle>
            <DialogContent>
                <div style={{paddingTop: '5px'}}>
                    <FormControl fullWidth>
                        <InputLabel>Prioridad</InputLabel>
                        <Select value={priority} label="Prioridad"
                            onChange={(e) => setPriority(e.target.value)}>
                            <MenuItem key={ComplaintPriorityEnum.HIGH}
                                value={ComplaintPriorityEnum.HIGH}>{ComplaintPrioritySpanishEnum.HIGH}
                            </MenuItem>
                            <MenuItem key={ComplaintPriorityEnum.MEDIUM}
                                value={ComplaintPriorityEnum.MEDIUM}>{ComplaintPrioritySpanishEnum.MEDIUM}
                            </MenuItem>
                            <MenuItem key={ComplaintPriorityEnum.LOW}
                                value={ComplaintPriorityEnum.LOW}>{ComplaintPrioritySpanishEnum.LOW}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }} style={{
                justifyContent: 'flex-end', paddingTop: '5px',
                paddingLeft: '0', paddingRight: '0'
            }}>
                <div style={{ marginRight: '27px' }}>
                    <CancelButton handleClick={handleClose}>Cancelar</CancelButton>
                    <ConfirmButton handleClick={selectPriority} disabled={!priority}>
                        <strong>Guardar</strong>
                    </ConfirmButton>
                </div>
            </DialogActions>
        </Dialog>
    );
}