import { FC, useEffect, useMemo, useState } from 'react';
import { PaginationState } from '@tanstack/react-table';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import Skeleton from 'react-loading-skeleton';
import { StyledTitleContainer } from '../../../design/theme/shared-style';
import moment from 'moment/moment';
import { getEwaListReport } from '../../../api/company/company.api';
import { BalanceDetailModal } from './BalanceDetailModal';
import { Box, Button, Typography } from '@mui/material';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import { generateTransactionReport } from '../../../components/User/Employee/Utils/employee.utils';
import { StyledChartCardBox, StyledUpperChartCardBox } from '../Dashboard';
import {
  EwaInformationResponse,
  GroupedTransactions,
  Transaction,
  TransactionResume,
} from '../Interface/dashboardInterface';

const EwaListTable: FC<{
  ewaList?: TransactionResume[],
  disableClick?: boolean,
  disableTitle?: boolean,
  fullTransactions?: boolean,
  exportButton?: boolean,
  showTotalsBox?: boolean
}> = ({ ewaList, disableClick, disableTitle, fullTransactions, exportButton, showTotalsBox }) => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [balanceSelected, setBalanceSelected] = useState<EwaInformationResponse | null>(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [totalsData, setTotalsData] = useState<GroupedTransactions[]>([]);

  const getList = async () => {
    try {
      setLoading(true);
      const ewaList = await getEwaListReport();
      const formattedData = flattenEwaListWithFrequency(ewaList);
      const mergedTransactions = mergeTransactionsByUser(formattedData);
      mergedTransactions[0].allTransactionsInPeriod.sort((a: any, b: any) => {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        return dateB - dateA;
      });
      setTableData(mergedTransactions);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const flattenEwaListWithFrequency = (ewaList: any): any[] => {
    const flattenedList = [];
    for (const frequency of Object.keys(ewaList)) {
      const transactions = ewaList[frequency];
      for (const transaction of transactions) {
        const transactionWithFrequency = {
          ...transaction,
          transactionFrequency: frequency,
        };
        flattenedList.push(transactionWithFrequency);
      }
    }
    return flattenedList;
  };

  const mergeTransactionsByUser = (transactions: any[]): any[] => {
    const mergedTransactions: any = {};
    transactions.forEach(transaction => {
      const userId = transaction.user.id;
      if (mergedTransactions[userId]) {
        const existingTransaction = mergedTransactions[userId];
        existingTransaction.receivable += transaction.receivable;
        existingTransaction.subtotal += transaction.subtotal;
        if (new Date(transaction.date) > new Date(existingTransaction.date)) {
          existingTransaction.date = transaction.date;
        }
        existingTransaction.allTransactionsInPeriod.push(transaction);
      } else {
        mergedTransactions[userId] = {
          ...transaction,
          allTransactionsInPeriod: [transaction],
        };
      }
    });
    return Object.values(mergedTransactions);
  };

  const unifyAllTransactionsInPeriod = (data: EwaInformationResponse[]): Transaction[] => {
    const unifiedTransactions: Transaction[] = [];

    data.forEach((item) => {
      if (item.allTransactionsInPeriod && item.allTransactionsInPeriod.length > 0) {
        unifiedTransactions.push(...item.allTransactionsInPeriod);
      }
    });

    return unifiedTransactions;
  }

  const handleRowClick = (row: any) => {
    setBalanceSelected(row.original);
    setShowDetailModal(true);
  };

  const exportData = async () => {
    let allTransactions: any;
    if(ewaList) {
      allTransactions = ewaList;
    } else {
      allTransactions = unifyAllTransactionsInPeriod(tableData);
    }
    await generateTransactionReport(allTransactions);
  };

  const groupAndSumByFrequency = (transactions: Transaction[]): GroupedTransactions[] => {
    const accumulator: { [key: string]: number } = {};
    transactions.forEach((transaction) => {
      const { transactionFrequency, subtotal } = transaction;
      if (accumulator[transactionFrequency]) {
        accumulator[transactionFrequency] += subtotal;
      } else {
        accumulator[transactionFrequency] = subtotal;
      }
    });
    const result: GroupedTransactions[] = Object.keys(accumulator).map((frequency) => ({
      transactionFrequency: frequency,
      totalSubtotal: accumulator[frequency],
    }));
    return result;
  };

  const getGridTemplateColumns = (length: number) => {
    if (length === 1) return '1fr';
    if (length === 2) return 'repeat(2, 1fr)';
    return length % 2 === 0 ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)';
  };

  const getGridColumn = (length: number, index: number) => {
    if (length === 1) return '1 / 2';
    if (length === 2) return `${index + 1} / ${index + 2}`;
    if (length % 2 === 0) {
      return `${(index % 2) + 1} / ${(index % 2) + 2}`;
    } else {
      return `${(index % 3) + 1} / ${(index % 3) + 2}`;
    }
  };

  useEffect(() => {
    if (!ewaList) {
      getList();
    } else {
      setTableData(ewaList);
    }
  }, []);

  useEffect(() => {
    if (tableData) {
      const totals = groupAndSumByFrequency(tableData);
      setTotalsData(totals);
    }
  }, [tableData]);

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => {
      const baseColumns = [
        {
          accessorKey: 'id',
          header: 'ID',
          size: 250,
          hidden: true,
          enableHiding: false,
          enableColumnActions: false,
          enableSorting: false,
          enableColumnOrdering: false,
        },
        {
          accessorKey: 'subtotal',
          header: 'Subtotal',
          size: 250,
          hidden: true,
          enableHiding: false,
          enableColumnActions: false,
          enableSorting: true,
          enableColumnOrdering: false,
          Cell: (props: any) => {
            // @ts-ignore
            const amount = props.cell.getValue<any>();
            return <span>{`$${amount?.toLocaleString() || '-'}`}</span>;
          },
        },
        {
          accessorKey: 'receivable',
          header: 'Total',
          size: 250,
          hidden: true,
          enableHiding: false,
          enableColumnActions: false,
          enableSorting: true,
          enableColumnOrdering: false,
          Cell: (props: any) => {
            // @ts-ignore
            const amount = props.cell.getValue<any>();
            return <span>{`$${amount?.toLocaleString() || '-'}`}</span>;
          },
        },
        {
          accessorKey: 'date',
          header: fullTransactions ? 'Fecha de transacción' : 'Fecha de última transacción',
          size: 250,
          hidden: true,
          enableHiding: false,
          enableColumnActions: false,
          enableSorting: true,
          enableColumnOrdering: false,
          Cell: (props: any) => {
            // @ts-ignore
            if (props.cell.getValue<string>() && props.cell.getValue<string>() !== 'null') {
              // @ts-ignore
              const formattedDate = moment.utc(props.cell.getValue<string>())
                .local()
                .format('DD/MM/YYYY');
              return <span>{formattedDate}</span>;
            } else {
              return <span>--</span>;
            }
          },
        },
      ];

      if (!fullTransactions) {
        baseColumns.splice(1, 0,
          {
            accessorKey: 'user.companyEmployeeNumber',
            header: 'Número de empleado',
            size: 250,
            hidden: true,
            enableHiding: false,
            enableColumnActions: false,
            enableSorting: true,
            enableColumnOrdering: false,
          },
          {
            accessorKey: 'user.name',
            header: 'Nombre',
            size: 250,
            hidden: true,
            enableHiding: false,
            enableColumnActions: false,
            enableSorting: true,
            enableColumnOrdering: false,
          },
          {
            accessorKey: 'user.firstLastname',
            header: 'Apellido Paterno',
            size: 250,
            hidden: true,
            enableHiding: false,
            enableColumnActions: false,
            enableSorting: true,
            enableColumnOrdering: false,
          },
          {
            accessorKey: 'user.secondLastname',
            header: 'Apellido Materno',
            size: 250,
            hidden: true,
            enableHiding: false,
            enableColumnActions: false,
            enableSorting: true,
            enableColumnOrdering: false,
          },
          {
            accessorKey: 'transactionFrequency',
            header: 'Frecuencia de pago',
            size: 250,
            hidden: true,
            enableHiding: false,
            enableColumnActions: false,
            enableSorting: true,
            enableColumnOrdering: false,
          },
        );
      }

      return baseColumns;
    },
    [fullTransactions],
  );

  return (
    <>
      {!disableTitle &&
        <p style={{ fontWeight: 'bold', color: '#666666', marginBottom: '20px', fontSize: '1.2rem' }}>Retiros en el
          periodo actual</p>
      }
      {
        showTotalsBox && totalsData && (
          <StyledUpperChartCardBox
            style={{
              display: 'grid',
              gridTemplateColumns: getGridTemplateColumns(totalsData.length),
              gap: '10px',
            }}
          >
            {totalsData.map((group, index) => (
              <StyledChartCardBox
                key={index}
                style={{
                  boxSizing: 'border-box',
                  gridColumn: getGridColumn(totalsData.length, index),
                }}
              >
                <div
                  style={{
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '20px',
                    marginBottom: '0px',
                  }}
                >
                  <Typography
                    variant="h4"
                    style={{
                      color: 'rgba(77,87,115,255)',
                      fontSize: '55px',
                      fontWeight: '900',
                    }}
                  >
                    {!loading ? `$${group.totalSubtotal.toLocaleString()}` : (
                      <Skeleton
                        style={{
                          width: '50px',
                          height: '68px',
                        }}
                      />
                    )}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ color: 'rgba(77,87,115,255)', fontSize: '20px', marginTop: '20px' }}
                  >
                    Subtotal periodo {group.transactionFrequency}
                  </Typography>
                </div>
              </StyledChartCardBox>
            ))}
          </StyledUpperChartCardBox>
        )
      }
      <div style={{ width: '100%' }}>
        {loading ? (
          <Skeleton
            style={{ width: '100%', height: '200px', marginTop: '50px' }}
          />
        ) : tableData && tableData.length > 0 ? (
          <MaterialReactTable
            initialState={{ density: 'compact' }}
            enableStickyHeader
            enableStickyFooter
            columns={columns}
            defaultColumn={{
              minSize: 20,
              maxSize: 160,
              size: 50,
            }}
            data={tableData}
            muiTablePaperProps={{
              elevation: 0,
              sx: {
                borderRadius: '0',
              },
            }}
            enableRowActions={false}
            enableRowSelection={false}
            enableRowOrdering={false}
            enableHiding={false}
            enableFullScreenToggle={false}
            enableMultiRowSelection={false}
            positionActionsColumn="first"
            onPaginationChange={setPagination}
            muiTablePaginationProps={{
              rowsPerPageOptions: [10, 20, 50],
            }}
            state={{
              isLoading: loading,
              pagination,
              columnVisibility: { id: false },
            }}
            muiTableBodyRowProps={({ row }) =>
              disableClick
                ? {}
                : {
                  onClick: () => handleRowClick(row),
                  style: { cursor: 'pointer' },
                }
            }
          />
        ) : (
          <StyledTitleContainer>
            <p>Su listado se encuentra vacío</p>
          </StyledTitleContainer>
        )}
      </div>
      { !loading && exportButton && tableData && tableData.length > 0 &&
        <Box mb={1} mr={2}>
          <Button
            style={{ marginTop: '25px' }}
            variant="outlined"
            onClick={exportData}
            startIcon={<BrowserUpdatedIcon />}>
            Exportar datos
          </Button>
        </Box>
      }
      {balanceSelected &&
        <BalanceDetailModal
          open={showDetailModal}
          onClose={() => setShowDetailModal(false)}
          balanceSelected={balanceSelected}
        ></BalanceDetailModal>
      }
    </>
  );
};

export default EwaListTable;
