/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from 'utilities/http/http';
import { CompanyList } from '../../components/AppBar/SideNavBar';

const baseUrl = '/client';

const getClientInformationUrl = () => `${baseUrl}/client-information`;

export async function getClientInformation(): Promise<{
    clientName: string,
    companies: CompanyList[]
}> {
    try {
        const response = await http.get<{
            success: boolean;
            data: any;
        }>(getClientInformationUrl());
        if (response.success) {
            return response.data;
        } else {
            throw new Error('Error getClientInformation');
        }
    } catch (err: any) {
        console.log(err);
        throw new Error(err.message);
    }
}
