/* eslint-disable @typescript-eslint/no-explicit-any */
import { UserEditApi } from 'types/user-edit-api.dto';
import { UserRequestApi } from 'types/user-request-api.dto';
import { UserResetPass } from 'types/user-reset-pass.dto';
import { Profile, User } from 'types/user.dto';
import { http } from 'utilities/http/http';
import { AuthorizationRequestsDto } from '../../types/authorizationRequest.dto';

const baseUrl = '/user';

const sendVerificationTokenUrl = () => `${baseUrl}/verificationToken`;
const verifyTokenUrl = (code: string) => `${baseUrl}/verifyToken/${code}`;
const listAuthorizationsUrl = () => `${baseUrl}/authorization/listAuthorizations`;
const authorizeNewUserUrl = () => `${baseUrl}/authorization/authorizeNewUser`;
const authorizeEditUserUrl = () => `${baseUrl}/authorization/authorizeEditUser`;
const authorizeNewNotificationUrl = () => `${baseUrl}/authorization/authorizeNewNotification`;
const rejectAuthorizationUrl = () => `${baseUrl}/authorization/rejectAuthorization`;
const validateUserAndGetInfoUrl = () => `/auth/validateUserAndGetInfo`;

export async function login(
  email: string,
  password: string,
): Promise<{ user: User; token: string }> {
  try {
    const response = await http.post<{
      success: boolean;
      data: { user: User; token: string };
    }>('/auth/login/', {
      email,
      password,
    });

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Login');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function loginAuth0(
  email: string,
  authCode: string,
): Promise<{ user: User; token: string }> {
  try {
    const response = await http.post<{
      success: boolean;
      data: { user: User; token: string };
    }>(`/auth/loginAuth0?authCode=/${authCode}`, {
      email,
      authCode,
    });
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error loginAuth0');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function listUser(): Promise<Array<User>> {
  try {
    const response = await http.get<{
      success: boolean;
      data: Array<User>;
    }>('/user/');
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error List Users');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function listUserEmployees(): Promise<Array<User>> {
  try {
    const response = await http.get<{
      success: boolean;
      data: Array<User>;
    }>('/user/company/employee');
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error List Companies');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function listUserEmployeesDropdown(): Promise<Array<{
  id: string,
  name: string,
  firstLastname: string
}>> {
  try {
    const response = await http.get<{
      success: boolean;
      data: Array<{
        id: string,
        name: string,
        firstLastname: string
      }>;
    }>('/user/company/employee?basicInfo=true');
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error List Companies');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function listCompanies(): Promise<any> {
  try {
    const response = await http.get<{
      success: boolean;
      data: Array<any>;
    }>('/company/allCompanies');
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error List Companies');
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function listBanks(): Promise<any> {
  try {
    const response = await http.get<{
      success: boolean;
      data: Array<any>;
    }>('/user/allBanks');
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error List Banks');
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function listCompanyEmployees(
  companyId: string,
  searchText?: string | null
): Promise<Array<User>> {
  try {
    const response = await http.get<{
      success: boolean;
      data: Array<User>;
    }>(`/company/companyEmployees?company=${companyId}${(searchText && `&searchText=${searchText}`) || ''}`);
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error List Companies');
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function listSearchTextEmployees(
  searchText?: string | null,
  companyId?: string | null,
): Promise<Array<User>> {
  try {
    const response = await http.get<{
      success: boolean;
      data: Array<User>;
    }>(`/company/employeeSearchText?searchText=${searchText}${(companyId && `&companyId=${companyId}`) || ''}`);
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error List Companies');
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function createUser(user: UserRequestApi): Promise<User> {
  try {
    const response = await http.post<{
      success: boolean;
      data: User;
    }>('/user/', user);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Crate User');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function editUser(id: string, user: UserEditApi): Promise<User> {
  try {
    const response = await http.put<{
      success: boolean;
      data: User;
    }>(`/user/${id}`, user);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Edit User');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function deleteUser(id: string): Promise<User> {
  try {
    const response = await http.delete<{
      success: boolean;
      data: User;
    }>(`/user/${id}`);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Delete User');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function activateUser(id: string): Promise<User> {
  try {
    const response = await http.patch<{
      success: boolean;
      data: User;
    }>(`/user/activate/${id}`);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Activate User');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function deactivateUser(id: string): Promise<User> {
  try {
    const response = await http.patch<{
      success: boolean;
      data: User;
    }>(`/user/deactivate/${id}`);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Deactivate User');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function setUserPassword(
  token: string,
  password: string,
): Promise<User> {
  try {
    const response = await http.patch<{
      success: boolean;
      data: User;
    }>('/user/set-password', { token, password });

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Set Password');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function revokeInvitation(id: string): Promise<User> {
  try {
    const response = await http.patch<{
      success: boolean;
      data: User;
    }>(`/user/revoke/${id}`);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Set Password');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function resetUserPassword(user: UserResetPass): Promise<User> {
  try {
    const response = await http.patch<{
      success: boolean;
      data: User;
    }>('/user/reset-password', user);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Reset Password');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }

}

export async function getDropdownNotificationFilter(companySelected?: string): Promise<{
  locationsToSend: string[],
  areasToSend: string[],
  profiles: Profile[]
}> {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(`user/company/dropdown-notification-options/${companySelected ? companySelected : ''}`);
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error List Locations');
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function updateUserPassword(userId: string, newPassword: string): Promise<boolean> {
  try {
    const response = await http.patch<{
      success: boolean;
      data: any;
    }>(`user/update-password/${userId ? userId : ''}`, {newPassword});
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Updating password');
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function sendVerificationToken(): Promise<boolean> {
  try {
    const response = await http.post<{
      success: boolean;
      data: boolean;
    }>(sendVerificationTokenUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error sendVerificationToken');
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function verifyTokenService(code: string): Promise<boolean> {
  try {
    const response = await http.post<{
      success: boolean;
      data: boolean;
    }>(verifyTokenUrl(code));
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error verifyToken');
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function listAuthorizations(): Promise<AuthorizationRequestsDto[]> {
  try {
    const response = await http.get<{
      success: boolean;
      data: AuthorizationRequestsDto[];
    }>(listAuthorizationsUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error listAuthorizations');
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function authorizeNewUser(requestSelected: AuthorizationRequestsDto): Promise<boolean> {
  try {
    const response = await http.post<{
      success: boolean;
      data: boolean;
    }>(authorizeNewUserUrl(), {requestSelected});
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error authorizeNewUser');
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function authorizeEditUser(requestSelected: AuthorizationRequestsDto): Promise<boolean> {
  try {
    const response = await http.post<{
      success: boolean;
      data: boolean;
    }>(authorizeEditUserUrl(), {requestSelected});
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error authorizeEditUser');
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function authorizeNewNotification(requestSelected: AuthorizationRequestsDto): Promise<boolean> {
  try {
    const response = await http.post<{
      success: boolean;
      data: boolean;
    }>(authorizeNewNotificationUrl(), {requestSelected});
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error authorizeNewNotification');
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function rejectAuthorizationRequest(requestSelected: AuthorizationRequestsDto): Promise<boolean> {
  try {
    const response = await http.post<{
      success: boolean;
      data: boolean;
    }>(rejectAuthorizationUrl(), {requestSelected});
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error rejectAuthorizationRequest');
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function validateUserAndGetInfo(
  companyId: string,
  authCode: string,
): Promise<{ user: User; token: string }> {
  try {
    const response = await http.post<{
      success: boolean;
      data: { user: User; token: string };
    }>(validateUserAndGetInfoUrl(), {
      companyId,
      authCode,
    });
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error validateUserAndGetInfo');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}