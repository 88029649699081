import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { toTitleCase } from 'helpers/text.helpers';
import { useEffect, useRef, useState } from 'react';

export default function UserDropDown(props: any) {
  const [inputValue, setInputValue] = useState('');
  const [users, setUsers] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);
  let counter = 0;

  useEffect(() => {
    setUsers(props.users);
  }, [props.users]);

  const handleInputChange = (event: React.ChangeEvent<any>, newInputValue: string, reason: string) => {
    if (reason === 'input') {
      setInputValue(newInputValue);
    } else {
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
    if (newInputValue.length === 0) {
      setOpen(false);
    }
  };

  return (
    <Box>
      <Autocomplete
        disableClearable
        options={users}
        getOptionLabel={(option: any) => toTitleCase(`${option.name} ${option.firstLastname} #${counter++}`)}
        style={{ width: '100%' , margin: '10px 0px'}}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField {...params} inputRef={inputRef} label="Buscar usuario" variant="outlined" />
        )}
        onChange={(event, newValue) => {
          props.setUser(newValue?.id);
          setInputValue('');
          setOpen(false);
        }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
      />
    </Box>
  );
}
