import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { CancelButton } from 'components/Shared/Buttons/CancelButton';
import { ConfirmButton } from 'components/Shared/Buttons/ConfirmButton';
import { CloseDialogButton } from 'design/Button/CloseDialogButton';
import { selectUser } from 'global-state/selectors';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { User } from 'types/user.dto';
import { validateInputEmail } from '../../../helpers/validation.helpers';

export const EditAdminModal: FC<{
  userToEdit: User;
  onClose: () => void;
  onSubmit: (values: { name: string; email: string; password: string }) => void;
  open: boolean;
}> = ({ open, userToEdit, onClose, onSubmit }) => {
  const [username, setUsername] = useState<string>(userToEdit.name);
  const [userEmail, setUserEmail] = useState<string>(userToEdit.email);
  const [userPassword, setUserPassword] = useState<string>('');
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);

  const loggedUser = useSelector(selectUser);

  useEffect(() => {
    setUsername(userToEdit.name);
    setUserEmail(userToEdit.email);
    setUserPassword('');
    validateEmail(userToEdit.email);
  }, [userToEdit]);

  const validateEmail = (email: string) => {
    const isEmailValid = validateInputEmail(email);
    setIsEmailValid(isEmailValid);
  };

  useEffect(() => {
    validateEmail(userEmail);
  }, [userEmail]);

  const handleSubmit = () => {
    if (!username) {
      toast.error('El nombre es requerido', {
        position: 'top-center',
      });
      return;
    }

    if (!userEmail) {
      toast.error('El email es requerido', {
        position: 'top-center',
      });
      return;
    }

    if (!isEmailValid) {
      toast.error('El formato del email no es válido', {
        position: 'top-center',
      });
      return;
    }

    onSubmit({
      email: userEmail,
      name: username,
      password: userPassword,
    });
    onClose();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle textAlign="left" mb={2}>
        Editar administrador
        {` ${userToEdit.email}`}
        <CloseDialogButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            mt={1}
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
              padding: '2',
            }}
          >
            <TextField
              key="email"
              label="Email"
              name="email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              error={!isEmailValid}
              helperText={!isEmailValid ? "Por favor, ingresa un email válido." : ""}
            />
            <TextField
              key="name"
              label="Nombre"
              name="name"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />

            <TextField
              label="Nueva contraseña"
              id="password"
              name="password"
              type="password"
              helperText="Si deseas cambiar la contraseña ingresa una nueva, para mantener la actual deja el campo en blanco"
              value={userPassword}
              onChange={(e) => setUserPassword(e.target.value)}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <CancelButton handleClick={onClose}>Cancelar</CancelButton>
        <ConfirmButton disabled={!isEmailValid} handleClick={handleSubmit}>
          <strong>Guardar</strong>
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};
