export enum MonthsSpanish {
    ene = 'enero',
    feb = 'febrero',
    mar = 'marzo',
    abr = 'abril',
    may = 'mayo',
    jun = 'junio',
    jul = 'julio',
    ago = 'agosto',
    sep = 'septiembre',
    oct = 'octubre',
    nov = 'noviembre',
    dic = 'diciembre',
}