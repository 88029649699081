import React, { FC } from 'react';
import { Rating } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

export const StarScoreDashboard: FC<{
  score: number,
  maxRating?: number,
  starColor?: string,
}> = ({ score, maxRating = 5, starColor = '#bb86fc' }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Rating name="size-large"
              defaultValue={score || 0}
              precision={0.5}
              size="large"
              max={maxRating}
              icon={<StarIcon style={{ color: starColor, fontSize: '3.3rem' }}/>}
              emptyIcon={<StarIcon style={{ fontSize: '3.3rem' }}/>}
              readOnly
      />
    </div>
  );
};