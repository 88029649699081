import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FC, useEffect } from 'react';
import { CloseDialogButton } from '../../../design/Button/CloseDialogButton';
import { Title } from '../../../design/theme/shared-style';
import { ConfirmButton } from '../../../components/Shared/Buttons/ConfirmButton';
import { User } from '../../../types/user.dto';
import { LabelInformation, StandarInformation } from '../../ComplaintMailbox/complaintMailboxStyle';


export const EditUserDetailsModal: FC<{
  onClose: () => void,
  open: boolean,
  submit: () => void,
  reject: () => void,
  userSelected?: User[] | null,
  newNetSalary?: any,
  newClabe?: any
}> = ({ onClose, open, userSelected, newNetSalary, newClabe, submit, reject }) => {

  const handlerClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    submit();
    onClose();
  };

  const handleReject = () => {
    reject();
    onClose();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle textAlign="left" mb={1} style={{ marginBottom: '0px' }}>
        <Title>Autorización de Modificación de Usuario</Title>
        <CloseDialogButton onClose={handlerClose} />
      </DialogTitle>
      <DialogContent>
        {
          newNetSalary && typeof newNetSalary.salaryAmount === "number" &&
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex' }}>
              <LabelInformation style={{ color: '#7d7d7d' }}>Salario actual:</LabelInformation>
              <StandarInformation>${(userSelected?.[0] && (userSelected[0].netSalary as any)?.salaryAmount) || ''}</StandarInformation>
            </div>
            <div style={{ display: 'flex', marginLeft: '1rem' }}>
              <LabelInformation style={{ color: '#7d7d7d' }}>Salario actualizado:</LabelInformation>
              <StandarInformation>${newNetSalary.salaryAmount}</StandarInformation>
            </div>
          </div>
        }
        {
          newClabe?.code &&
          <div style={{ display: 'flex', marginTop: '1rem' }}>
            <div style={{ display: 'flex' }}>
              <LabelInformation style={{ color: '#7d7d7d' }}>CLABE actual:</LabelInformation>
              <StandarInformation>{userSelected?.[0]?.clabes?.[0]?.code || '-'}</StandarInformation>
            </div>
            <div style={{ display: 'flex', marginLeft: '1rem' }}>
              <LabelInformation style={{ color: '#7d7d7d' }}>CLABE actualizada:</LabelInformation>
              <StandarInformation>{newClabe?.code || ''}</StandarInformation>
            </div>
            </div>
        }
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <ConfirmButton handleClick={handleSubmit}>
          <strong>Autorizar</strong>
        </ConfirmButton>
        <ConfirmButton handleClick={handleReject}>
          <strong>Rechazar</strong>
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};