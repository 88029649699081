import { Box } from '@mui/material';
import { Router } from 'Router';
import { SideNavBar } from 'components/AppBar/SideNavBar';
import { selectUser } from 'global-state/selectors';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { IntlContext } from 'utilities/i18n/intl.context';
import { useAuth } from './auth/AuthContext';
import { AppRoute } from './enums/app-route.enum';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 300;

const StyledMainBox = styled(Box)`
  padding: 1rem 2rem 2rem 2rem;
  min-height: 100vh;
  width: 100%;
  margin-top: 70px;

  a {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const StyledMainBoxLogin = styled(Box)`
  padding: 1rem 2rem 2rem 2rem;
  min-height: 100vh;
  width: 100%;
  background: #70026d;

  a {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

function App() {
  // necessary to force a render when locale is updated
  useContext(IntlContext);
  const loggedUser = useSelector(selectUser);
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  let autoLog = true;
  useEffect(() => {
    const handleUnauthorizedAccess = () => {
      logout();
    };
    window.addEventListener('unauthorizedAccess', handleUnauthorizedAccess);
    return () => {
      window.removeEventListener('unauthorizedAccess', handleUnauthorizedAccess);
    };
  }, []);
  if (isAuthenticated && !loggedUser && autoLog) {
    autoLog = !autoLog;
    if (autoLog) {
      navigate(`${AppRoute.Auth0Callback}?code=alreadyLogged`)
    }
  }

  return (
    <Box sx={{ display: 'flex' }}>
      {loggedUser && (
        <Box>
          <SideNavBar drawerWidth={drawerWidth} />
        </Box>
      )}
      {loggedUser ? (
        <StyledMainBox>
          <main>
            <Router />
          </main>
        </StyledMainBox>
      ) : (
        <StyledMainBoxLogin>
          <main>
            <Router />
          </main>
        </StyledMainBoxLogin>
      )}
    </Box>
  );
}

export default App;
