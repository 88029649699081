import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import { loginAuth0 } from '../api/user/user.api';
import { useDispatch } from 'react-redux';
import { setAuthTokenAction, setLoggedUserAction } from 'global-state/actions';
import { genericErrorHandler } from 'helpers/error.helpers';
import { toast } from 'components/Toast/Toast';
import { UserRole } from '../enums/user-enum';
import { AppRoute } from '../enums/app-route.enum'

const Auth0Process = async (email, dispatch, navigate, getAccessTokenSilently) => {
    try {
        const tokenSilently = await getAccessTokenSilently();
        const { user, token } = await loginAuth0(email, tokenSilently);
        if (user, token) {
            dispatch(setAuthTokenAction(token));
            dispatch(setLoggedUserAction(user));
            if(user.role === UserRole.ADMIN) {
                navigate(AppRoute.Admins);
            } else if (user.role === UserRole.COMPANY) {
                navigate(AppRoute.Dashboard);
            }
        } else {
            navigate(AppRoute.LogIn);
        }
    } catch (error) {
        genericErrorHandler(error);
        const invalidCredentials = (error instanceof Error && error.message.includes('Invalid credentials'));
        if (invalidCredentials) {
            toast.error(
                'Credenciales inválidas',
                { position: 'top-center' },
            );
        } else {
            toast.error(
                'Algo salió mal. Por favor, inténtalo nuevamente más tarde.',
                { position: 'top-center' },
            );
        }
        navigate(AppRoute.LogIn);
    }
}

export const Auth0Callback = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    const { isAuthenticated, user, getAccessTokenSilently, loginWithRedirect } = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    if(!isAuthenticated && !code) {
        loginWithRedirect();
    } else if(user) {
        Auth0Process(user.email, dispatch, navigate, getAccessTokenSilently);
    }
    return (
        <div>
        </div>
    );
};

export default Auth0Callback;