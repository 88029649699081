/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from 'utilities/http/http';
import { Settings } from '../../../backend/src/entities/settings.entity';
import { ConfigDataDTO } from '../../types/config-data.dto';

export async function saveConfig(
    data: ConfigDataDTO
): Promise<boolean> {
  try {
    const response = await http.post<boolean>('/settings/saveConfigData/', data);
    if (response) {
      return response;
    } else {
      throw new Error('Error saveConfig');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function getCurrentSettings(): Promise<{
    success: boolean;
    data: Settings;
  }> {
  try {
    const response = await http.get<{
      success: boolean;
      data: Settings;
    }>('/settings/getCurrentSettings/');
    if (response.success) {
      return response;
    } else {
      throw new Error('Error getCurrentSettings');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function savePrivacyAgreement(
    data: { privacyData: string }
): Promise<boolean> {
  try {
    const response = await http.post<boolean>('/settings/savePrivacyAgreement/', data);
    if (response) {
      return response;
    } else {
      throw new Error('Error savePrivacyAgreement');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function getCurrentPrivacyAgreement(): Promise<{
  success: boolean;
  data: string;
}> {
try {
  const response = await http.get<{
    success: boolean;
    data: string;
  }>('/settings/getCurrentPrivacyAgreement/');
  if (response.success) {
    return response;
  } else {
    throw new Error('Error getCurrentPrivacyAgreement');
  }
} catch (err: any) {
  throw new Error(err.message);
}
}

export async function saveTermsAndConditions(
  data: { termsAndConditionsData: string }
): Promise<boolean> {
try {
  const response = await http.post<boolean>('/settings/saveTermsAndConditions/', data);
  if (response) {
    return response;
  } else {
    throw new Error('Error saveTermsAndConditions');
  }
} catch (err: any) {
  throw new Error(err.message);
}
}

export async function getCurrentTermsAndConditions(): Promise<{
success: boolean;
data: string;
}> {
try {
const response = await http.get<{
  success: boolean;
  data: string;
}>('/settings/getCurrentTermsAndConditions/');
if (response.success) {
  return response;
} else {
  throw new Error('Error getCurrentTermsAndConditions');
}
} catch (err: any) {
throw new Error(err.message);
}
}