import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { validateUserAndGetInfo } from '../api/user/user.api';
import { setAuthTokenAction, setLoggedUserAction } from '../global-state/actions';
import { AppRoute } from '../enums/app-route.enum';
import { useDispatch, useSelector } from 'react-redux';
import { UserRoleEnum } from '../types/user-role.enum';
import { selectAuthToken, selectUser } from '../global-state/selectors';
import { toast } from '../components/Toast/Toast';

export const Auth0Landing = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const companyId = searchParams.get('company');
  const auth0Code = searchParams.get('code');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validationSent = useRef(false);
  const loggedUser = useSelector(selectUser);
  const authToken = useSelector(selectAuthToken);

  const getValidUser = async (companyId: string, auth0Code: string) => {
    return await validateUserAndGetInfo(companyId, auth0Code);
  };

  const validateAndGetUserInfo = async () => {
    try {
      if (!validationSent.current && companyId && auth0Code) {
        validationSent.current = true;
        const validUser = await getValidUser(companyId, auth0Code);
        if (validUser.user && validUser.token) {
          dispatch(setAuthTokenAction(validUser.token));
          dispatch(setLoggedUserAction(validUser.user));
          if (validUser.user.role === UserRoleEnum.COMPANY) {
            navigate(AppRoute.Dashboard);
          }
        } else {
          navigate(AppRoute.LogIn);
        }
      }
    } catch (error) {
      navigate(AppRoute.LogIn);
      toast.error('El usuario no tiene permisos válidos para iniciar sesión')
      console.error('Error validateAndGetUserInfo:', error);
    }
  };

  useEffect(() => {
    if (!authToken && !loggedUser) {
      validateAndGetUserInfo();
    }
  }, []);

  return (
    <div>
    </div>
  );
};

export default Auth0Landing;