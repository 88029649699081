import { FC, useEffect, useState } from 'react';
import { FcDefaultProps } from 'types/fc-default-props';
import styled from 'styled-components';
import { ThemeProvider } from '@mui/material';
import { Button } from 'design';
import { violetTheme } from '../../design/theme/palette';
import { genericErrorHandler } from '../../helpers/error.helpers';
import { toast } from 'react-toastify';
import {
  getCurrentPrivacyAgreement,
  savePrivacyAgreement,
} from '../../api/config/config.api';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import { Spinner } from '../Spinner/Spinner';

const StyledTextEditorAndBtnContainer = styled.div`
  width: 60%;
  margin: 5% 10%;
  height: 600px;
`;

const StyledTextEditorContainer = styled.div`
  height: 100%;
`;

const StyleButtonContainer = styled.div`
  height: 20%;
  width: 60%;
  margin: auto;
`;

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ align: [] }],

    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }],

    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['link'],
    [{ color: [] }, { background: [] }],

    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const formats = [
  'bold',
  'italic',
  'underline',
  'strike',
  'align',
  'list',
  'indent',
  'size',
  'header',
  'link',
  'color',
  'background',
  'clean',
];

export const Privacy: FC<FcDefaultProps> = () => {
  const { quill, quillRef } = useQuill({ modules, formats });
  const [privacyAgreement, setPrivacyAgreement] = useState('');
  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState('#BF8BFF');

  useEffect(() => {
    getCurrentPrivacyAgreementAndSetValue();
  }, []);

  useEffect(() => {
    if (quill && privacyAgreement) {
      const parsed = JSON.parse(privacyAgreement);
      quill.setContents(parsed);
    }
  }, [quill, privacyAgreement]);

  const getCurrentPrivacyAgreementAndSetValue = async () => {
    try {
      const currentSettings = await getCurrentPrivacyAgreement();
      if (Object.keys(currentSettings.data).length !== 0) {
        setPrivacyAgreement(currentSettings.data);
      }
      setLoading(false);
    } catch (error) {
      genericErrorHandler(error);
      toast.error('Error al setear valores iniciales');
    }
  };

  const handleSubmit = async () => {
    try {
      const values = JSON.stringify(quill?.getContents());
      await savePrivacyAgreement({ privacyData: values });
      toast.success('Información actualizada');
    } catch (error) {
      genericErrorHandler(error);
      toast.error('Error al guardar');
    }
  };

  return (
    <>
      {loading ? (
        <Spinner color={color} loading={loading} />
      ) : (
        <StyledTextEditorAndBtnContainer>
          <StyledTextEditorContainer>
            <div style={{ width: '100%', height: '80%' }}>
              <div ref={quillRef} />
            </div>
          </StyledTextEditorContainer>
          <StyleButtonContainer>
            <ThemeProvider theme={violetTheme}>
              <Button
                onClick={handleSubmit}
                type="submit"
                color="primary"
                variant="filled"
                fullWidth
              >
                Guardar
              </Button>
            </ThemeProvider>
          </StyleButtonContainer>
        </StyledTextEditorAndBtnContainer>
      )}
    </>
  );
};
