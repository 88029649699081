import React, { FC, useEffect, useState } from 'react';
import { reasonsChart } from '../chartjs-config';
import { Line } from 'react-chartjs-2';
import { getSpecialityCountData } from '../../../api/health/health.api';
import { MonthsSpanish } from '../../../enums/months.enum';
import { firstLetterUppercase } from '../../../utilities/i18n/helpers';
import { getRandomColorSolid, getRandomColorTransparent } from '../utils';

interface Dataset {
  label: string;
  data: number[];
  fill: boolean;
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
  maxBarThickness: number;
  borderRadius: number;
}

interface ChartData {
  labels: string[];
  datasets: Dataset[];
}

export const ReasonsLastTwelveMonthsDashboard: FC = () => {
  const [data, setData] = useState<{reasonsData: any[]} | null>(null);
  const [mappedInformation, setMappedInformation] = useState<ChartData | null>(null);
  const loadData = () => {
    if(!data) return;
    const consultingData = data.reasonsData;
    const chartData = {
      labels: consultingData.map((dataPoint: any) => firstLetterUppercase(MonthsSpanish[dataPoint.month as keyof typeof MonthsSpanish])),
      datasets: consultingData[0].consultingCount.map((reasonData: any, index: number) => {
        const colorSolid = getRandomColorSolid(index);
        const colorTransparent = getRandomColorTransparent(index);
        const datasetData = consultingData.map((monthData: any) => {
          const countData = monthData.consultingCount.find((item: any) => item.reason === reasonData.reason);
          return countData ? countData.count : 0;
        });
        const average = datasetData.reduce((sum: number, value: number) => sum + value, 0) / datasetData.length;

        return {
          label: firstLetterUppercase(reasonData.reason),
          data: datasetData,
          fill: true,
          backgroundColor: colorTransparent,
          borderColor: colorSolid,
          borderWidth: 2,
          maxBarThickness: 50,
          borderRadius: 10,
          average: average,
        };
      })
        .sort((a: any, b: any) => a.average - b.average),
    };
    chartData.datasets.forEach((dataset: any) => {
      delete dataset.average;
    });
    setMappedInformation(chartData);
  };
  const loadInformationFromBackend = async () => {
    const data = await getSpecialityCountData();
    setData(data);
  };
  useEffect(() => {
    loadInformationFromBackend();
  }, []);
  useEffect(() => {
    if(data?.reasonsData) {
      loadData();
    }
  }, [data]);
  return (
    <>
      {mappedInformation && (
        <Line data={mappedInformation} options={reasonsChart} />
      )}
    </>
  );
};