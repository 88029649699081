import { createTheme } from '@mui/material';
import { Palette } from 'design/types/palette';

const theme = createTheme();
const { augmentColor } = theme.palette;
const createColor = (mainColor: string) =>
  augmentColor({ color: { main: mainColor } });

export const violetTheme = createTheme({
  palette: { primary: createColor('#70026d') },
});

export const palette: Palette = {
  primary: {
    main: '#000000',
    light: '#000000',
    dark: '#000000',
    invert: '#fff',
  },
  secondary: {
    main: '#FFCBD6',
    light: '#FFCBD6',
    dark: '#FFCBD6',
    invert: '#fff',
  },
  error: {
    main: '#d32f2f',
    light: '#ef5350',
    dark: '#c62828',
    invert: '#fff',
  },
  warning: {
    main: '#ed6c02',
    light: '#ff9800',
    dark: '#e65100',
    invert: '#fff',
  },
  info: {
    main: '#0288d1',
    light: '#03a9f4',
    dark: '#01579b',
    invert: '#fff',
  },
  success: {
    main: '#2e7d32',
    light: '#4caf50',
    dark: '#1b5e20',
    invert: '#fff',
  },
  grey: {
    main: '#888',
    light: '#ddd',
    dark: '#004947',
    invert: '#fff',
  },
  white: {
    main: '#fff',
    light: '#fff',
    dark: '#fff',
    invert: '#fff',
  },
  black: {
    main: '#000',
    light: '#000',
    dark: '#000',
    invert: '#000',
  },
  green: {
    main: '#004947',
    light: '#CDDDDD',
    dark: '#004947',
    invert: '#fff',
  },
  pink: {
    main: '#FFCBD666',
    light: '#FFCBD666',
    dark: '#FFCBD666',
    invert: '#fff',
  },
  blue: {
    main: '#233F9F',
    light: '#233F9F',
    dark: '#233F9F',
    invert: '#fff',
  },
};
