import { toast } from "react-toastify";
import { validateEmail } from "./validation.helpers";

export const salaryOnDemandIsSelected = (value: string): boolean => {
    if (value == '1' || value == '3' || value == '4' || value == '5') {
        return true;
    }
    return false;
};

export const isUserFormValid = (isSalaryOnDemandSelected: boolean, values: any, isProfile = false): boolean => {
    const validations = [
        { condition: !values.name, message: 'El nombre es requerido' },
        { condition: !values.firstLastname, message: 'El apellido paterno es requerido' },
        { condition: !values.secondLastname, message: 'El apellido materno es requerido' },
        { condition: !values.sex, message: 'El sexo es requerido' },
        { condition: !values.rfc, message: 'El RFC es requerido' },
        { condition: !values.curp, message: 'El CURP es requerido' },
        { condition: !values.email, message: 'El correo electrónico es requerido' },
        { condition: !values.birthday, message: 'La fecha de nacimiento es requerida' },
        { condition: !validateEmail(values.email), message: 'Ingresa un correo electrónico en formato valido' },
        { condition: !!(values.clabe && !values.bank), message: 'No se puede cargar CLABE sin banco' },
    ];
    if (isSalaryOnDemandSelected) {
        validations.push(
            { condition: !values.startDate, message: 'La fecha de ingreso es requerida' },
            { condition: !values.netSalary, message: 'El salario neto es requerido' },
            { condition: !values.paymentFrequency, message: 'La frecuencia de pago es requerida' },
            { condition: !values.clabe, message: 'La CLABE es requerida' },
            { condition: !values.bank, message: 'El banco es requerido' },
        );
    }
    if(isProfile) {
        validations.push(
            { condition: !values.profile, message: 'El perfil de servicio es requerido' },
        );
    } else {
        validations.push(
          { condition: !values.appLayout, message: 'Servicios disponibles es requerido' },
        );
    }
    const failedValidation = validations.find(validation => validation.condition);
    if (failedValidation) {
        toast.error(failedValidation.message, {
            position: 'top-center',
        });
        return false;
    }
    return true;
};