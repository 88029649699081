import { ComplaintDTO, ComplaintResponseDTO, ComplaintTypeDTO } from "../../types/complaint.dto";
import { http } from "../../utilities/http/http";
import { ComplaintStatusEnum, ComplaintPriorityEnum } from '../../enums/complaint.enum';

const baseUrl = '/complaint';

const listComplaintsUrl = () => `${baseUrl}`;
const updateComplaintStatusAndIndexUrl = () => `${baseUrl}/update-complaint`;
const asociateUserUrl = () => `${baseUrl}/asociate-user`;
const updateComplaintPriorityUrl = () => `${baseUrl}/priority-complaint`;
const markComplaintAsReadedUrl = () => `${baseUrl}/mark-readed`;
const deleteUserRelationUrl = (data: { complaintId: string, userId: string }) =>
  `${baseUrl}/delete-user?complaintId=${data.complaintId}&userId=${data.userId}`;
const getComplaintCategoriesUrl = () => `${baseUrl}/get-categories`;
const updateComplaintCategoryUrl = () => `${baseUrl}/update-category`;
const updateComplaintResolutionUrl = () => `${baseUrl}/update-resolution`;
const archiveComplaintUrl = (complaintId: string) => `${baseUrl}/archive-complaint/${complaintId}`;
const unarchiveComplaintUrl = (complaintId: string) => `${baseUrl}/unarchive-complaint/${complaintId}`;
const loadArchivedComplaintsUrl = () => `${baseUrl}/get-archived-complaints`;

export async function listComplaints(): Promise<ComplaintResponseDTO> {
    try {
        const response = await http.get<{
            success: boolean;
            data: any;
        }>(listComplaintsUrl());
        if (response.success) {
            return response.data;
        } else {
            throw new Error('Error List Complaints');
        }
    } catch (err: any) {
        throw new Error(err.message);
    }
}

export async function updateComplaintStatusAndIndex(newData: {
    id: string,
    newStatus: ComplaintStatusEnum,
    index: number
}): Promise<boolean> {
    try {
        const response = await http.patch<{
            success: boolean;
            data: any;
        }>(updateComplaintStatusAndIndexUrl(), newData);
        if (response.success) {
            return response.data;
        } else {
            throw new Error('Error Updating Complaint');
        }
    } catch (err: any) {
        throw new Error(err.message);
    }
}

export async function asociateUser(data: {
    complaintId: string,
    userId: string
}): Promise<ComplaintDTO> {
    try {
        const response = await http.patch<{
            success: boolean;
            data: any;
        }>(asociateUserUrl(), data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error('Error Updating Complaint');
        }
    } catch (err: any) {
        throw new Error(err.message);
    }
}

export async function updateComplaintPriority(newData: {
    complaintId: string,
    newPriority: ComplaintPriorityEnum,
    holdingState?: {
        droppableId: string,
        index: number
    } | null
}): Promise<ComplaintDTO> {
    try {
        const response = await http.patch<{
            success: boolean;
            data: any;
        }>(updateComplaintPriorityUrl(), newData);
        if (response.success) {
            return response.data;
        } else {
            throw new Error('Error Updating Complaint');
        }
    } catch (err: any) {
        throw new Error(err.message);
    }
}

export async function markComplaintAsReaded(complaintId: string): Promise<ComplaintDTO> {
    try {
        const response = await http.patch<{
            success: boolean;
            data: any;
        }>(markComplaintAsReadedUrl(), {complaintId});
        if (response.success) {
            return response.data;
        } else {
            throw new Error('Error markComplaintAsReaded');
        }
    } catch (err: any) {
        throw new Error(err.message);
    }
}

export async function deleteUserRelation(data: {
    complaintId: string,
    userId: string
}): Promise<ComplaintDTO> {
    try {
        const response = await http.delete<{
            success: boolean;
            data: any;
        }>(deleteUserRelationUrl(data));
        if (response.success) {
            return response.data;
        } else {
            throw new Error('Error Updating Complaint');
        }
    } catch (err: any) {
        throw new Error(err.message);
    }
}

export async function getComplaintCategories(): Promise<ComplaintTypeDTO[]> {
    try {
        const response = await http.get<{
            success: boolean;
            data: any;
        }>(getComplaintCategoriesUrl());
        if (response.success) {
            return response.data;
        } else {
            throw new Error('Error Getting Complaint Categories');
        }
    } catch (err: any) {
        throw new Error(err.message);
    }
}

export async function updateComplaintCategory(data: {
    complaintId: string,
    newCategory: string,
}): Promise<ComplaintDTO> {
    try {
        const response = await http.patch<{
            success: boolean;
            data: any;
        }>(updateComplaintCategoryUrl(), data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error('Error updateComplaintCategory');
        }
    } catch (err: any) {
        throw new Error(err.message);
    }
}

export async function updateComplaintResolution(data: {
    complaintId: string,
    newBodyResolution: string,
    holdingState?: {
        droppableId: string,
        index: number
    } | null
}): Promise<ComplaintDTO> {
    try {
        const response = await http.patch<{
            success: boolean;
            data: any;
        }>(updateComplaintResolutionUrl(), data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error('Error updateComplaintResolution');
        }
    } catch (err: any) {
        throw new Error(err.message);
    }
}

export async function archiveComplaintBackend(complaintId: string): Promise<ComplaintDTO> {
    try {
        const response = await http.patch<{
            success: boolean;
            data: any;
        }>(archiveComplaintUrl(complaintId));
        if (response.success) {
            return response.data;
        } else {
            throw new Error('Error archiveComplaintBackend');
        }
    } catch (err: any) {
        throw new Error(err.message);
    }
}

export async function unarchiveComplaintBackend(complaintId: string): Promise<ComplaintDTO> {
    try {
        const response = await http.patch<{
            success: boolean;
            data: any;
        }>(unarchiveComplaintUrl(complaintId));
        if (response.success) {
            return response.data;
        } else {
            throw new Error('Error unarchiveComplaintBackend');
        }
    } catch (err: any) {
        throw new Error(err.message);
    }
}

export async function loadArchivedComplaints(): Promise<ComplaintDTO[]> {
    try {
        const response = await http.get<{
            success: boolean;
            data: ComplaintDTO[];
        }>(loadArchivedComplaintsUrl());
        if (response.success) {
            return response.data;
        } else {
            throw new Error('Error loadArchivedComplaints');
        }
    } catch (err: any) {
        throw new Error(err.message);
    }
}