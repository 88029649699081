import { Box, Tab, Tabs, styled } from '@mui/material';
import { FC } from 'react';
import { FcDefaultProps } from 'types/fc-default-props';
import { TabPanel } from '../../components/TabPanel/TabPanel';
import React from 'react';
import { ConfigForm } from '../../components/ConfigComponents/ConfigForm';
import { Privacy } from '../../components/ConfigComponents/Privacy';
import { TermsAndConditions } from '../../components/ConfigComponents/TermsAndConditions';

const StyledBoxWithPadding = styled(Box)`
  padding-left: 2vw;
  padding-right: 2vw;

  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const ConfigSection: FC<FcDefaultProps> = () => {

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <StyledBoxWithPadding>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Configuración" {...a11yProps(0)} />
          <Tab label="Privacidad" {...a11yProps(1)} />
          <Tab label="Términos y Condiciones" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ConfigForm />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Privacy />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TermsAndConditions />
      </TabPanel>
    </StyledBoxWithPadding>
  );
};