/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from 'utilities/http/http';
import { MonthCounterResponseDTO } from '../../types/month-counter-response.dto';
import { EmployeeByAreaResponseDTO } from '../../types/employee-by-area-response.dto';
import { RegisterByMonthDTO } from '../../types/register-by-month.dto';
import { DeactivatedByMonthDTO } from '../../types/deactivated-by-month.dto';
import { EwaReport, GenerationStatsReport, StatusHistoryReport, TrendingServiceReport } from '../../types/reports.dto';

const baseUrl = '/company';

const getLoginsVirtualizedUrl = () => `${baseUrl}/dashboard/login-virtualized`;
const getLoginsByMonthUrl = () => `${baseUrl}/dashboard/login-by-months`;
const getCoveredEmployeesUrl = () => `${baseUrl}/dashboard/covered-employees`;
const getAppLayoutAllowedUrl = () => `${baseUrl}/app-layout-allowed`;
const getEnrolledEmployeesUrl = () => `${baseUrl}/dashboard/enrolled-employees`;
const getEmployeesByAreaUrl = () => `${baseUrl}/dashboard/employees-by-area`;
const getRegisterByMonthUrl = () => `${baseUrl}/dashboard/register-by-month`;
const getDeactivatedByMonthUrl = () => `${baseUrl}/dashboard/deactivated-by-month`;
const getClicksChartUrl = () => `${baseUrl}/dashboard/clicks-chart`;
const getClickByServiceUrl = () => `${baseUrl}/dashboard/clicks-by-service`;
const getCompanyHeadCountUrl = () => `${baseUrl}/dashboard/headcount`;
const getCompanyHeadCountByAgeUrl = () => `${baseUrl}/dashboard/headcount-by-age`;
const getListProfilesUrl = (companyId: string) => `${baseUrl}/profileList?companyId=${companyId}`;
const getConsultingScoreUrl = () => `${baseUrl}/dashboard/consulting-score`;
const getRotationByAreaUrl = () => `${baseUrl}/dashboard/rotation-by-area`;
const getRotationBySexUrl = () => `${baseUrl}/dashboard/rotation-by-sex`;
const getRotationBySalaryUrl = () => `${baseUrl}/dashboard/rotation-by-salary`;
const getRotationBySeniorityUrl = () => `${baseUrl}/dashboard/rotation-by-seniority`;
const getEmployeesByGenerationUrl = () => `${baseUrl}/dashboard/employees-by-generation`;
const getTrendingServiceUrl = () => `${baseUrl}/dashboard/trending-service`;
const getUserStatusHistoryUrl = () => `${baseUrl}/dashboard/user-status-history`;
const getEwaServiceReportUrl = () => `${baseUrl}/dashboard/ewa-report`;
const getEwaHistoricalReportUrl = (userId: string) => `${baseUrl}/dashboard/ewa-historical-report?userId=${userId}`;

export async function getLoginsByMonth(): Promise<MonthCounterResponseDTO[]> {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getLoginsByMonthUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getLoginsByMonth');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getLoginsVirtualizedData(): Promise<{yearCount: number, monthCount: number}> {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getLoginsVirtualizedUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getLoginsVirtualizedData');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getCoveredEmployees() {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getCoveredEmployeesUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getCoveredEmployees');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getAppLayoutAllowed() {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getAppLayoutAllowedUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getAppLayoutAllowed');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getEnrolledEmployees() {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getEnrolledEmployeesUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getEnrolledEmployees');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getEmployeesByArea(): Promise<EmployeeByAreaResponseDTO[]> {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getEmployeesByAreaUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getEmployeesByArea');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getRegisterByMonth(): Promise<RegisterByMonthDTO[]> {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getRegisterByMonthUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getRegisterByMonth');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getDeactivatedByMonth(): Promise<DeactivatedByMonthDTO[]> {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getDeactivatedByMonthUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getDeactivatedByMonth');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getRotationByArea(): Promise<any[]> {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getRotationByAreaUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getRotationByArea');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getRotationBySex(): Promise<any[]> {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getRotationBySexUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getRotationBySex');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getRotationBySalary(): Promise<any[]> {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getRotationBySalaryUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getRotationBySalary');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getRotationBySeniority(): Promise<any[]> {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getRotationBySeniorityUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getRotationBySeniority');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getClicksChart() {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getClicksChartUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getClicksChart');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getClickByService() {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getClickByServiceUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getClickByService');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getCompanyHeadCount() {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getCompanyHeadCountUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getCompanyHeadCount');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getUserStatusHistory(): Promise<StatusHistoryReport[]> {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getUserStatusHistoryUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getUserStatusHistory');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getCompanyHeadCountByAge() {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getCompanyHeadCountByAgeUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getCompanyHeadCountByAge');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getListProfiles(companyId: string) {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getListProfilesUrl(companyId));
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getListProfiles getListProfiles');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getConsultingScore() {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getConsultingScoreUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getConsultingScore');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getEmployeesByGeneration(): Promise<GenerationStatsReport[]> {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getEmployeesByGenerationUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getEmployeesByGeneration');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getTrendingService(): Promise<TrendingServiceReport[]> {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getTrendingServiceUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getTrendingService');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getEwaListReport(): Promise<EwaReport> {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getEwaServiceReportUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getEwaListReport');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getEwaHistoricalReport(userId: string): Promise<EwaReport> {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getEwaHistoricalReportUrl(userId));
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getEwaHistoricalReport');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}