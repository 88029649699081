import {
    Autocomplete, Box, Dialog, DialogActions, DialogContent, DialogTitle,
    FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField
} from "@mui/material";
import { FC, useState, useEffect, ChangeEvent } from 'react';
import { CloseDialogButton } from "../../design/Button/CloseDialogButton";
import { TextInput } from 'design';
import { CancelButton } from "../Shared/Buttons/CancelButton";
import { ConfirmButton } from "../Shared/Buttons/ConfirmButton";
import { InputChangeEvent } from "../../design/types/input-change-event";
import { toast } from "react-toastify";
import { firstLetterUppercase } from '../../utilities/i18n/helpers';
import { UserRoleEnum } from "../../types/user-role.enum";
import { useSelector } from "react-redux";
import { selectUser } from "../../global-state/selectors";
import { Textarea, Title } from "../../design/theme/shared-style";
import { SelectedValue } from "../../enums/dropdown.enum";
import { CurrentNotificationInfoDTO, EditedNotificationInfoDTO } from "../../types/notifications.dto";

export const EditNotificationModal: FC<{
    open: boolean, onClose: () => void, onSubmit: (values: EditedNotificationInfoDTO) => void,
    currentNotificationInfo: CurrentNotificationInfoDTO,
}> = ({ open, onClose, onSubmit, currentNotificationInfo }) => {
    const [notificationTitle, setNotificationTitle] = useState<string>(currentNotificationInfo.currentTitle || "");
    const [notificationBody, setNotificationBody] = useState<string>(currentNotificationInfo.currentBody || "");
    const [area, setArea] = useState<string>(SelectedValue.ALL);
    const [location, setLocation] = useState<string>(SelectedValue.ALL);
    const loggedUser = useSelector(selectUser);

    useEffect(() => {
        reloadForm();
    }, [currentNotificationInfo]);

    const reloadForm = () => {
        setNotificationTitle(currentNotificationInfo.currentTitle);
        setNotificationBody(currentNotificationInfo.currentBody);
        setArea(currentNotificationInfo.currentArea);
        setLocation(currentNotificationInfo.currentLocation);
    }

    const handleSubmit = () => {
        if (!notificationTitle) {
            toast.error('El título es requerido.');
            return;
        }
        if (!notificationBody) {
            toast.error('El mensaje es requerido.');
            return;
        }
        onSubmit({
            notificationBody,
            notificationTitle,
            notificationId: currentNotificationInfo.notificationId
        });
    }
    const handleAreaChange = (event: SelectChangeEvent<string>) => {
        setArea(event.target.value);
    }
    const handleLocationChange = (event: SelectChangeEvent<string>) => {
        setLocation(event.target.value);
    }
    const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const maxCharactersBody = 181;
        if (notificationBody.length < maxCharactersBody || event.target.value.length < notificationBody.length) {
            setNotificationBody(event.target.value);
        }
    }
    const handleTitleChange = (event: InputChangeEvent<string>) => {
        const maxCharactersTitle = 40;
        if (notificationTitle.length < maxCharactersTitle || event.target.value.length < notificationTitle.length) {
            setNotificationTitle(event.target.value);
        }
    }
    const resetForm = () => {
        setArea(SelectedValue.ALL);
        setLocation(SelectedValue.ALL);
        setNotificationBody('');
        setNotificationTitle('');
    }
    const handlerClose = () => {
        onClose();
        resetForm();
    }

    return (
        <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle textAlign="left" mb={1} style={{ marginBottom: '0px' }}>
                <Title>Editar notificación</Title>
                <CloseDialogButton onClose={handlerClose} />
            </DialogTitle>
            <DialogContent>
                {loggedUser?.role === UserRoleEnum.ADMIN &&
                    currentNotificationInfo && (
                        <div style={{ marginBottom: '25px', marginTop: '7px' }}>
                            <Box>
                                <Autocomplete
                                    options={[currentNotificationInfo.notificationCompanyName || 'Todas las empresas']}
                                    style={{ width: '100%' }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Buscar por empresa" variant="outlined" />
                                    )}
                                    value={currentNotificationInfo.notificationCompanyName || 'Todas las empresas'}
                                    disabled={true}
                                />
                            </Box>
                        </div>
                    )}
                <div style={{ marginTop: '7px' }}>
                    <TextInput
                        label="Título"
                        id="notificationTitle"
                        name="notificationTitle"
                        type="text"
                        onChange={handleTitleChange}
                        value={notificationTitle}
                        variant="outlined"
                        fullWidth
                    />
                </div>
                <Textarea minRows="4" placeholder="Escribe aquí tu mensaje" value={notificationBody}
                    onChange={handleTextChange} style={{ maxWidth: '100%', width: '100%', minWidth: '100%', minHeight: '30px' }}></Textarea>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%', marginTop: '25px' }}>
                        <DialogActions sx={{ p: '1.25rem' }} style={{
                            justifyContent: 'space-between', paddingTop: '3px',
                            paddingLeft: '0px', paddingRight: '10px'
                        }}>
                            <div style={{ width: '100%' }}>
                                <FormControl fullWidth disabled={true}>
                                    <InputLabel id="app-layout-select-label">Área</InputLabel>
                                    <Select
                                        labelId="app-area-select-label"
                                        id="area"
                                        value={area || ''}
                                        label="Áreas"
                                        onChange={handleAreaChange}
                                    >
                                        <MenuItem key="Todas las areas" value={SelectedValue.ALL}>
                                            Todas las áreas
                                        </MenuItem>
                                        {area !== SelectedValue.ALL && <MenuItem key={area} value={area}>
                                            {firstLetterUppercase(area)}
                                        </MenuItem>}
                                    </Select>
                                </FormControl>
                            </div>
                        </DialogActions>
                    </div>
                    <div style={{ width: '50%', marginTop: '25px' }}>
                        <DialogActions sx={{ p: '1.25rem' }} style={{
                            justifyContent: 'space-between', paddingTop: '3px',
                            paddingLeft: '10px', paddingRight: '0px'
                        }}>
                            <div style={{ width: '100%' }}>
                                <FormControl fullWidth disabled={true}>
                                    <InputLabel id="app-layout-select-label">Ubicación</InputLabel>
                                    <Select
                                        labelId="app-location-select-label"
                                        id="location"
                                        value={location || ''}
                                        label="Ubicación"
                                        onChange={handleLocationChange}
                                    >
                                        <MenuItem key="Todas las ubicaciones" value={SelectedValue.ALL}>
                                            Todas las ubicaciones
                                        </MenuItem>
                                        {location !== SelectedValue.ALL && <MenuItem key={location} value={location}>
                                            {firstLetterUppercase(location)}
                                        </MenuItem>}
                                    </Select>
                                </FormControl>
                            </div>
                        </DialogActions>
                    </div>
                </div>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <CancelButton handleClick={handlerClose}>Cancelar</CancelButton>
                <ConfirmButton handleClick={handleSubmit} disabled={
                    currentNotificationInfo.currentBody === notificationBody &&
                    currentNotificationInfo.currentTitle === notificationTitle
                }>
                    <strong>Guardar</strong>
                </ConfirmButton>
            </DialogActions>
        </Dialog>
    )
};