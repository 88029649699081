import { IconButton, InputAdornment, ThemeProvider, Typography } from '@mui/material';
import { setUserPassword } from 'api/user/user.api';
import logo from 'assets/logo-full-white.png';
import { Form } from 'components/Form/Form';
import { toast } from 'components/Toast/Toast';
import { Button, Card, TextInput } from 'design';
import { AppRoute } from 'enums/app-route.enum';
import { FormikHelpers } from 'formik';
import { selectAuthToken, selectUser } from 'global-state/selectors';
import { genericErrorHandler } from 'helpers/error.helpers';
import { Align } from 'layout';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FcDefaultProps } from 'types/fc-default-props';
import { UserRoleEnum } from 'types/user-role.enum';
import { intl } from 'utilities/i18n/intl.utility';
import * as Yup from 'yup';
import { violetTheme } from '../../design/theme/palette';
import { VisibilityOff, Visibility } from '@mui/icons-material';

const StyledAlign = styled(Align)`
  height: calc(100vh - 9rem);
  width: 100%;
`;

const StyledTitleContainer = styled.div`
  position: absolute;
  top: 10%;
  left: 60px;
  z-index: 9999;
  color: #ffffff !important;
  margin: 0;
  margin-left: -32px;
`;

const initialValues = {
  password: '',
  passwordConfirmation: '',
};

const validationSchema = Yup.object({
  password: Yup.string()
    .min(6, 'Debe tener al menos 6 caracteres de longitud')
    .required('Campo requerido'),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Las contraseñas deben coincidir',
  ),
});

type FormValues = {
  password: string;
  passwordConfirmation: string;
};

export const SetPassword: FC<FcDefaultProps> = () => {
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const authToken = useSelector(selectAuthToken);
  const { token } = useParams();
  const loggedUser = useSelector(selectUser);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => {
    try {
      if (token) {
        await setUserPassword(token, values.password);
        toast.success(
          'La contraseña ha sido actualizada correctamente, vuelve a loguearte desde la app',
          {
            position: 'top-center',
          },
        );
        setPasswordUpdated(true);
      }
      helpers.resetForm();
    } catch (error) {
      genericErrorHandler(error);
      toast.error(
        intl.translate({
          id: 'Ha ocurrido un error',
        }),
        { position: 'top-center' },
      );
    }
  };

  useEffect(() => {
    if (authToken && loggedUser) {
      if (loggedUser.role === UserRoleEnum.ADMIN) {
        navigate(AppRoute.Admins);
      } else {
        navigate(AppRoute.Dashboard);
      }
    }
  }, [authToken, location.state, navigate, loggedUser]);

  if (passwordUpdated) {
    return (
      <>
        <StyledTitleContainer>
          <img src={logo} alt="Logo Tewispi" style={{ maxWidth: '230px' }} />
        </StyledTitleContainer>
        <StyledAlign v-center h-start>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Card.Base>
              <div style={{ minHeight: '300px' }}>
                <Typography
                  variant="h4"
                  style={{
                    color: 'rgba(77,87,115,255)',
                    fontSize: '25px',
                    fontWeight: '900',
                  }}
                >
                  ¡Contraseña actualizada correctamente! Por favor, regresa a la app para autenticarte con tus nuevas credenciales.
                </Typography>
              </div>
            </Card.Base>
          </div>
        </StyledAlign>
      </>
    );
  }

  const handleToggleShowPassword = (isConfirmPassword?: boolean) => {
    if(isConfirmPassword) {
      setShowConfirmPassword(!showConfirmPassword)
    } else {
      setShowPassword(!showPassword);
    }
  };

  return (
    <>
      <StyledTitleContainer>
        <img src={logo} alt="Logo Tewispi" style={{ maxWidth: '230px' }} />
      </StyledTitleContainer>
      <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
      <Align v-center h-start style={{height: 'calc(100vh - 9rem)'}}>
        <Card.Base>
          <Form
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <>
                <Card.Body>
                  <Align column gap={1} style={{ minWidth: '350px' }}>
                    <TextInput
                      label="Nueva contraseña"
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      error={formik.errors.password}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleToggleShowPassword()}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <TextInput
                      label="Confirmar nueva contraseña"
                      id="passwordConfirmation"
                      name="passwordConfirmation"
                      type={showConfirmPassword ? "text" : "password"}
                      onChange={formik.handleChange}
                      value={formik.values.passwordConfirmation}
                      error={formik.errors.passwordConfirmation}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleToggleShowPassword(true)}
                              edge="end"
                            >
                              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ThemeProvider theme={violetTheme}>
                      <Button type="submit" variant="outlined" fullWidth>
                        Actualizar contraseña
                      </Button>
                    </ThemeProvider>
                  </Align>
                </Card.Body>
              </>
            )}
          </Form>
        </Card.Base>
      </Align>
      </div>
    </>
  );
};
