import { Company, User } from './user.dto';
import { Notifications } from './notifications.dto';


export type AuthorizationRequestsDto = {
  status: AuthorizationStatus | null;
  type: AuthorizationType | null;
  authorizationDate: Date | null;
  creator: User | null;
  company: Company | null;
  authorizer: User | null;
  usersEffected: User[];
  clabeAffected: any;
  netSalaryAffected: any;
  notificationAffected: Notifications | null;
};

export enum AuthorizationStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  TEMPORARY_DISABLED = 'TEMPORARY_DISABLED',
}

export enum AuthorizationType {
  CREATE_USER = 'CREATE_USER',
  EDIT_USER = 'EDIT_USER',
  SEND_NOTIFICATION = 'SEND_NOTIFICATION',
}