import { Dialog, DialogContent, DialogTitle, Skeleton, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { CloseDialogButton } from '../../../design/Button/CloseDialogButton';
import { Title } from '../../../design/theme/shared-style';
import { LabelInformation, StandarInformation } from '../../ComplaintMailbox/complaintMailboxStyle';
import { getEwaHistoricalReport } from '../../../api/company/company.api';
import { format, subMonths } from 'date-fns';
import { es } from 'date-fns/locale';
import { Bar } from 'react-chartjs-2';
import { transactionsChart } from '../chartjs-config';
import { StyledChartCardBox } from '../Dashboard';
import { firstLetterUppercase } from '../../../utilities/i18n/helpers';
import { MonthsSpanish } from '../../../enums/months.enum';
import { calculateAge, calculateTimeFrom, formatDate } from '../utils';
import { EwaInformationResponse, TransactionResume } from '../Interface/dashboardInterface';
import EwaListTable from './EwaListTable';

export const BalanceDetailModal: FC<{
  onClose: () => void,
  open: boolean,
  balanceSelected: EwaInformationResponse,
}> = ({ onClose, open, balanceSelected }) => {
  const [loading, setLoading] = useState(false);
  const [historicalData, setHistoricalData] = useState<TransactionResume[] | null>(null);
  const [historicalDataChart, setHistoricalDataChart] = useState<any>(null);

  const handlerClose = () => {
    setHistoricalData(null);
    onClose();
  };

  const loadHistoricalBalance = async (): Promise<void> => {
    try {
      setLoading(true);
      const ewaHistoricalList = await getEwaHistoricalReport(balanceSelected.user.id);
      ewaHistoricalList.historical.sort((a: any, b: any) => {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        return dateB - dateA;
      });
      setHistoricalData(ewaHistoricalList.historical);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const processHistoricalData = (historicalData:  TransactionResume[]) => {
    const currentDate = new Date();
    const monthlyData: Record<string, { month: string; amount: number }> = {};
    for (let monthOffset = 0; monthOffset < 12; monthOffset++) {
      const monthStart = subMonths(currentDate, 11 - monthOffset);
      const monthString = format(monthStart, 'MMM', { locale: es });
      const monthKey = format(monthStart, 'yyyy-MM');

      monthlyData[monthKey] = {
        month: monthString,
        amount: 0,
      };
    }
    for (const transaction of historicalData) {
      const transactionMonthKey = format(new Date(transaction.date), 'yyyy-MM');
      if (monthlyData[transactionMonthKey]) {
        monthlyData[transactionMonthKey].amount += transaction.subtotal || 0;
      }
    }
    const result = Object.values(monthlyData);
    return result;
  };

  const getLabelsFromData = (data: any[]): string[] => {
    return data.map((dataPoint) =>
      firstLetterUppercase(MonthsSpanish[dataPoint.month as keyof typeof MonthsSpanish])
    );
  };

  useEffect(() => {
    if(open) {
      loadHistoricalBalance();
    }
  }, [open]);

  useEffect(() => {
    if(historicalData) {
      const ewaChartData = processHistoricalData(historicalData);
      const labels = getLabelsFromData(ewaChartData);
      const chartDataFormatted = {
        labels: labels,
        datasets: [
          {
            label: 'Cantidad de dinero',
            data: ewaChartData.map((dataPoint) => dataPoint.amount),
            fill: false,
            backgroundColor: 'rgba(235,224,255,255)',
            borderColor: '#b590ff',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
        ],
      };
      setHistoricalDataChart(chartDataFormatted);
    }
  }, [historicalData]);

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle textAlign="left" mb={1} style={{ marginBottom: '0px' }}>
        <Title>Balance y transacciones de {balanceSelected.user.name} {balanceSelected.user.firstLastname}</Title>
        <CloseDialogButton onClose={handlerClose} />
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" sx={{ mt: 2 }} style={{ marginBottom: '16px' }}>Información del
          colaborador:</Typography>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', gap: '16px' }}>
          <div style={{
            flex: 1,
            padding: '16px',
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            backgroundColor: '#f9f9f9'
          }}>
            <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold', color: '#333' }}
                        style={{ marginBottom: '16px', fontSize: '1.25rem', marginTop: '0px' }}>Persona</Typography>
            <div style={{ display: 'flex', marginBottom: '8px' }}>
              <LabelInformation style={{ fontWeight: '500', color: '#555' }}>Nombre: </LabelInformation>
              <StandarInformation style={{ color: '#000' }}>{balanceSelected.user.name || '-'}</StandarInformation>
            </div>
            <div style={{ display: 'flex', marginBottom: '8px' }}>
              <LabelInformation style={{ fontWeight: '500', color: '#555' }}>Apellido Paterno: </LabelInformation>
              <StandarInformation
                style={{ color: '#000' }}>{balanceSelected.user.firstLastname || '-'}</StandarInformation>
            </div>
            <div style={{ display: 'flex', marginBottom: '8px' }}>
              <LabelInformation style={{ fontWeight: '500', color: '#555' }}>Apellido Materno: </LabelInformation>
              <StandarInformation
                style={{ color: '#000' }}>{balanceSelected.user.secondLastname || '-'}</StandarInformation>
            </div>
            <div style={{ display: 'flex', marginBottom: '8px' }}>
              <LabelInformation style={{ fontWeight: '500', color: '#555' }}>Edad: </LabelInformation>
              <StandarInformation
                style={{ color: '#000' }}>{calculateAge(balanceSelected.user.birthday) || '-'} años</StandarInformation>
            </div>
          </div>

          <div style={{
            flex: 1,
            padding: '16px',
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            backgroundColor: '#f9f9f9',
          }}>
            <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold', color: '#333' }}
                        style={{ marginBottom: '16px', fontSize: '1.25rem', marginTop: '0px' }}>Empresa</Typography>
            <div style={{ display: 'flex', marginBottom: '8px' }}>
              <LabelInformation style={{ fontWeight: '500', color: '#555' }}>Puesto: </LabelInformation>
              <StandarInformation style={{ color: '#000' }}>{balanceSelected.user.position || '-'}</StandarInformation>
            </div>
            <div style={{ display: 'flex', marginBottom: '8px' }}>
              <LabelInformation style={{ fontWeight: '500', color: '#555' }}>Área: </LabelInformation>
              <StandarInformation style={{ color: '#000' }}>{balanceSelected.user.area || '-'}</StandarInformation>
            </div>
            <div style={{ display: 'flex', marginBottom: '8px' }}>
              <LabelInformation style={{ fontWeight: '500', color: '#555' }}>Ubicación: </LabelInformation>
              <StandarInformation style={{ color: '#000' }}>{balanceSelected.user.location || '-'}</StandarInformation>
            </div>
            <div style={{ display: 'flex', marginBottom: '8px' }}>
              <LabelInformation style={{ fontWeight: '500', color: '#555' }}>Fecha de ingreso: </LabelInformation>
              <StandarInformation
                style={{ color: '#000' }}>{formatDate(balanceSelected.user.startDate) || '-'}</StandarInformation>
            </div>
            <div style={{ display: 'flex', marginBottom: '8px' }}>
              <LabelInformation style={{ fontWeight: '500', color: '#555' }}>Antigüedad: </LabelInformation>
              <StandarInformation
                style={{ color: '#000' }}>{calculateTimeFrom(balanceSelected.user.startDate, new Date()) || '-'}</StandarInformation>
            </div>
          </div>

          <div style={{
            flex: 1,
            padding: '16px',
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            backgroundColor: '#f9f9f9',
          }}>
            <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold', color: '#333' }}
                        style={{ marginBottom: '16px', fontSize: '1.25rem', marginTop: '0px' }}>Pago</Typography>
            <div style={{ display: 'flex', marginBottom: '8px' }}>
              <LabelInformation style={{ fontWeight: '500', color: '#555' }}>Salario Neto: </LabelInformation>
              <StandarInformation
                style={{ color: '#000' }}>${balanceSelected.user.netSalary?.toLocaleString() || '-'}</StandarInformation>
            </div>
            <div style={{ display: 'flex', marginBottom: '8px' }}>
              <LabelInformation style={{ fontWeight: '500', color: '#555' }}>Frecuencia de Pago: </LabelInformation>
              <StandarInformation
                style={{ color: '#000' }}>{balanceSelected.user.paymentFrequency || '-'}</StandarInformation>
            </div>
          </div>
        </div>
        <Typography variant="h6" sx={{ mt: 2 }} style={{ marginBottom: '16px' }}>Listado de transacciones periodo
          actual:</Typography>
        <EwaListTable
          ewaList={balanceSelected.allTransactionsInPeriod}
          disableTitle={true}
          disableClick={true}
          fullTransactions={true}
        ></EwaListTable>
        <div style={{
          display: 'flex',
          gap: '16px',
          marginTop: '16px',
        }}>
          <div style={{
            flex: 1,
            padding: '16px',
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            backgroundColor: '#f9f9f9'
          }}>
            <LabelInformation style={{ fontWeight: '500', color: '#555' }}>
              Subtotal del período:
              <StandarInformation style={{ color: '#000', fontWeight: 'bold', marginLeft: '10px' }}>
                ${balanceSelected.subtotal?.toLocaleString() || '-'}
              </StandarInformation>
            </LabelInformation>
          </div>

          <div style={{
            flex: 1,
            padding: '16px',
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            backgroundColor: '#f9f9f9'
          }}>
            <LabelInformation style={{ fontWeight: '500', color: '#555' }}>
              Total del período:
              <StandarInformation style={{ color: '#000', fontWeight: 'bold', marginLeft: '10px' }}>
                ${balanceSelected.receivable?.toLocaleString() || '-'}
              </StandarInformation>
            </LabelInformation>
          </div>
        </div>
        <Typography variant="h6" sx={{ mt: 2 }} style={{ marginBottom: '16px' }}>Grafico de transacciones
          historicas:</Typography>
        <StyledChartCardBox>
          <div>
            {loading ? (
              <Skeleton style={{ height: '200px' }} />
            ) : (
              historicalDataChart && <Bar data={historicalDataChart} options={transactionsChart} />
            )}
          </div>
        </StyledChartCardBox>
        <Typography variant="h6" sx={{ mt: 2 }} style={{ marginBottom: '16px' }}>Listado de transacciones
          historicas:</Typography>
        <div>
          {loading ? (
            <Skeleton style={{ height: '200px' }} />
          ) : (
            historicalData && (
              <EwaListTable
                ewaList={historicalData}
                disableTitle={true}
                disableClick={true}
                fullTransactions={true}
                exportButton={true}
              />
            )
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};