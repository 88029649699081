import { Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { FC, useState, useEffect } from 'react';
import { CloseDialogButton } from "../../design/Button/CloseDialogButton";
import { ComplaintDTO, ComplaintTypeDTO } from "../../types/complaint.dto";
import { CancelButton } from "../../components/Shared/Buttons/CancelButton";
import { ConfirmButton } from "../../components/Shared/Buttons/ConfirmButton";
import { toast } from "react-toastify";
import { updateComplaintCategory } from "../../api/complaint/complaint.api";


export const TypeSelectorModal: FC<{
    complaintToDetail: ComplaintDTO | null;
    reloadComplaints: () => Promise<void>;
    loadSavedComplaint?: (complaint: ComplaintDTO) => void;
    onClose: () => void;
    open: boolean;
    categories: ComplaintTypeDTO[];
}> = ({ open, onClose, complaintToDetail, reloadComplaints, loadSavedComplaint, categories }) => {
    const [category, setCategory] = useState(complaintToDetail?.type?.id?.toString() || '');
    const selectPriority = async () => {
        if (category && complaintToDetail) {
            toast.info('Actualizando categoría...', {
                position: toast.POSITION.TOP_CENTER,
            });
            const savedComplaint = await updateComplaintCategory({
                complaintId: complaintToDetail?.id,
                newCategory: category,
            });
            if(loadSavedComplaint && savedComplaint) {
                loadSavedComplaint(savedComplaint);
                toast.dismiss();
                toast.success('Categoría actualizada correctamente', {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        }
        reloadComplaints();
        handleClose();
    };
    const handleClose = () => {
        onClose();
        setCategory('');
    };

    useEffect(() => {
        setCategory(complaintToDetail?.type?.id.toString() || '');
    }, [complaintToDetail]);

    useEffect(() => {
        setCategory(complaintToDetail?.type.id.toString() || '');
    }, [open]);

    return (
        <Dialog open={open} fullWidth={true} maxWidth={'xs'}>
            <DialogTitle textAlign="left">
                <div>Cambiar categoría</div>
                <CloseDialogButton onClose={handleClose} />
            </DialogTitle>
            <DialogContent>
                <div style={{ paddingTop: '5px' }}>
                    <FormControl fullWidth>
                        <InputLabel>Categoría</InputLabel>
                        <Select value={category} label="Prioridad"
                            onChange={(e) => setCategory(e.target.value)}>
                            {
                                categories.map((category) => {
                                    return (
                                        <MenuItem key={category.id.toString()} value={category.id.toString()}>
                                            {category.name}
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }} style={{
                justifyContent: 'flex-end', paddingTop: '5px',
                paddingLeft: '0', paddingRight: '0'
            }}>
                <div style={{ marginRight: '27px' }}>
                    <CancelButton handleClick={handleClose}>Cancelar</CancelButton>
                    <ConfirmButton handleClick={selectPriority} disabled={!category}>
                        <strong>Guardar</strong>
                    </ConfirmButton>
                </div>
            </DialogActions>
        </Dialog>
    );
}