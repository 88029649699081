import { FC, useState, CSSProperties } from 'react';
import { FcDefaultProps } from 'types/fc-default-props';
import BounceLoader from "react-spinners/BounceLoader";


const override: CSSProperties = {
    display: "block",
    margin: "20% 0% 0% 40%",
    borderColor: "blue",
};

export const Spinner: FC<{ color: string, loading: boolean}> = ({ color, loading}) => {

    return (
        <>
           <div className="sweet-loading">
                        <BounceLoader
                            color={color}
                            loading={loading}
                            cssOverride={override}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                            size={60}
                        />
            </div>
        </>
    );
};