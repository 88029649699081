export enum ComplaintStatus {
    OPEN = 'Abierto',
    INVESTIGATION = 'En análisis',
    PROGRESS = 'En resolución',
    CLOSE = 'Cerrado',
}

export enum ComplaintPriorityEnum {
    HIGH = 'HIGH',
    MEDIUM = 'MEDIUM',
    LOW = 'LOW',
}

export enum ComplaintPrioritySpanishEnum {
    HIGH = 'Alta',
    MEDIUM = 'Media',
    LOW = 'Baja',
}

export enum ComplaintStatusEnum {
    OPEN = 'OPEN',
    INVESTIGATION = 'INVESTIGATION',
    PROGRESS = 'PROGRESS',
    CLOSE = 'CLOSE',
}