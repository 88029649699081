import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { toTitleCase } from 'helpers/text.helpers';
import { useEffect, useState } from 'react';

interface Company {
  id: string;
  entityName: string;
}

interface CompaniesDropDownProps {
  companies: Company[];
  setCompaniesFromDropDown: (ids: string[]) => void;
  disableMargin?: boolean;
  disableWidth?: boolean;
  defaultOptionRequired?: boolean;
  searchText?: string;
}

export default function MultiCompaniesDropDown(props: CompaniesDropDownProps) {
  const [inputValue, setInputValue] = useState('');
  const [selectedCompanies, setSelectedCompanies] = useState<Company[]>([{ id: 'all', entityName: 'Todas las empresas' }]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [styleDropdown, setStyleDropdown] = useState<React.CSSProperties>({});

  useEffect(() => {
    let companiesList = props.companies;
    if (props.defaultOptionRequired) {
      companiesList = [{ id: 'all', entityName: 'Todas las empresas' }, ...props.companies];
    }
    setCompanies(companiesList);
  }, [props.companies, props.defaultOptionRequired]);

  useEffect(() => {
    if (!props.disableMargin) {
      setStyleDropdown({ marginBottom: '40px', marginTop: '40px', width: '400px' });
    } else if (!props.disableWidth) {
      setStyleDropdown({ width: '400px' });
    }
  }, [props.disableMargin, props.disableWidth]);

  const handleChange = (event: any, newValue: Company[] | null) => {
    if (!newValue || newValue.length === 0) {
      return;
    }
    const lastElement = newValue[newValue.length - 1];
    if(lastElement.id === 'all') {
      setSelectedCompanies([{ id: 'all', entityName: 'Todas las empresas' }]);
      props.setCompaniesFromDropDown && props.setCompaniesFromDropDown(['all']);
    } else {
      const filteredNewValue = newValue.filter(company => company.id !== 'all');
      setSelectedCompanies(filteredNewValue);
      props.setCompaniesFromDropDown && props.setCompaniesFromDropDown(filteredNewValue.map(company => company.id));
    }
  };

  return (
    <Box style={styleDropdown}>
      <Autocomplete
        multiple
        value={selectedCompanies}
        options={companies}
        getOptionLabel={(option: Company) => toTitleCase(option.entityName)}
        style={{ width: '100%' }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label={props.searchText || "Buscar por empresa"} variant="outlined" />
        )}
        onChange={handleChange}
        disableClearable
      />
    </Box>
  );
}